
import {User} from './user';
import {Office} from './office';
/**
 * A class that can return the Asset model
 */
export class Asset {
  /** @param {Object} asset FastJson object. */
  constructor(asset = {}) {
    this.id = asset.id || null;
    this.name = asset.attributes.name || null;
    this.assetType = asset.attributes.asset_type || null;
    this.description = asset.attributes.description || null;
    this.serialNumber = asset.attributes.serial_number || null;
    if (asset.attributes.assignee.data.type === 'user') {
      const user = new User(asset.attributes.assignee.data);
      const office = new Office(asset.attributes.assignee.data.attributes.office);
      this.assigneeUser = {id: `${user.id}`, value: user.name} || null;
      this.assigneeOffice = {id: `${office.id}`, value: office.name} || null;
    } else {
      const office = new Office(asset.attributes.assignee.data);
      this.assigneeOffice = {id: `${office.id}`, value: office.name} || null;
    }
  }
}
