import Vue from 'vue';
import Vuex from 'vuex';
import VueAxios from 'vue-axios';
import axios from '../plugins/axios';

import menus from './modules/menus';
import session from './modules/session';
import company from './modules/company';
import offices from './modules/offices';
import teams from './modules/teams';
import holidays from './modules/holidays';
import departments from './modules/departments';
import users from './modules/users';
import leaves from './modules/leaves';
import assets from './modules/assets';
import documents from './modules/documents';
import goals from './modules/goals';
import calendars from './modules/calendars';
import worklogs from './modules/worklogs';
import googleAuth from './modules/googleAuth';
import helpDesks from './modules/helpDesks';
import kudos from './modules/kudos';
Vue.use(Vuex);
Vue.use(VueAxios, axios);

export default new Vuex.Store({
  state: {
    loading: false,
    currentPermission: ''
  },
  getters: {
    loading: (state) => state.loading,
    currentPermission: (state) => state.currentPermission
  },
  mutations: {
    setLoading: (state, isLoading) => (state.loading = isLoading),
    setCurrentPermission: (state, currentPermission) => (state.currentPermission = currentPermission)
  },
  actions: {
    setLoading({commit}, isLoading) {
      commit('setLoading', isLoading);
    },
    setPermission({commit}, currentPermission) {
      commit('setCurrentPermission', currentPermission);
    }
  },
  modules: {
    menus,
    session,
    company,
    offices,
    teams,
    departments,
    holidays,
    users,
    leaves,
    assets,
    documents,
    goals,
    calendars,
    worklogs,
    helpDesks,
    googleAuth,
    kudos
  }
});
