import {LeaveRequest} from 'models/leaveRequest';
import {GoogleEvent} from 'models/googleEvent';
import moment from 'moment';
import _ from 'lodash';
/**
 * A class that can return the Calendar configurations model
 */
export class CalendarEvent {
  /** @param {Object} event FastJson Object*/
  constructor(event = {}) {
    this.id = event.id || null;
    if (event.attributes) {
      this.groupId = event.attributes.group_id || null;
      this.details = _.startsWith(event.attributes.group_id, 'LEAVES') ? new LeaveRequest(event.attributes.details) :
        event.attributes.group_id === 'GOOGLE_EVENTS' ? new GoogleEvent(event.attributes.details) : [];
      this.multipleDays = event.attributes.multiple_days || false;
      this.start = moment(event.attributes.start_date).format() || null;
      this.end = event.attributes.end_date ? this.multipleDays ? moment(event.attributes.end_date).endOf('day').format() :
        moment(event.attributes.end_date).format() : null;
      this.allDay = event.attributes.full_day && !this.multipleDays;
      this.color = '#fff';
      this.backgroundColor = event.attributes.color || null;
      this.borderColor = event.attributes.color || null;
      this.classNames = this.allDay || this.multipleDays ? [] : ['secondary-color-text'];
      this.title = event.attributes.title ? this.allDay || this.multipleDays ? event.attributes.title :
        `${moment(this.start).format('hh:mma')} ${event.attributes.title}`: null;
    }
  }
}
