import {List, ListParams} from 'models/list';
import {Asset} from 'models/asset';

const state = {
  assets: new List()
};

const getters = {
  assets: (state) => state.assets
};

const actions = {
  async getAssets({commit, rootState}, options) {
    let params = new ListParams(options);
    if (options.uuid) {
      const uuid = options.uuid === 'me' ? rootState.session.currentUser.id : options.uuid;
      params = {...new ListParams(options), user_id: uuid};
    }
    commit('setLoading', true);
    const response = await this.$app.$http.get('/assets', {params: params});
    commit('setLoading', false);
    if (response.status === 200) {
      commit('setAssets', response.data);
    }
    return response.status;
  },
  async createAsset({commit}, data) {
    commit('setLoading', true);
    const response = await this.$app.$http.post('/assets', data);
    commit('setLoading', false);
    if (response.status === 200) {
      this.$app.$snotify.success(this.$app.$t('messages.success.updated', {
        entity: this.$app.$tc('models.asset.entity', 1)
      }));
    }
    return response;
  },
  async updateAsset({commit}, data) {
    commit('setLoading', true);
    const response = await this.$app.$http.put(`/assets/${data.assetId}`, data.form);
    commit('setLoading', false);
    if (response.status === 200) {
      this.$app.$snotify.success(this.$app.$t('messages.success.updated', {
        entity: this.$app.$tc('models.asset.entity', 1)
      }));
    }
    return response;
  },
  async destroyAsset({commit}, id) {
    commit('setLoading', true);
    const response = await this.$app.$http.delete(`/assets/${id}`);
    commit('setLoading', false);
    if (response.status === 200) {
      this.$app.$snotify.success(this.$app.$t('messages.success.deleted',
        {entity: this.$app.$tc('models.asset.entity', 1)}));
    } else {
      this.$app.$snotify.error(this.$app.$t('messages.error.deleted',
        {entity: this.$app.$tc('models.asset.entity', 1)}));
    }
    return response.status;
  }
};

const mutations = {
  setAssets: (state, data) => {
    state.assets = new List({items: data.items.map((asset) => new Asset(asset)), count: data.count});
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
