import Avatar from './Avatar';
import IconButton from './IconButton';
import Field from './Field';
import Form from './Form';
import Modal from './Modal';
import Loader from './Loader';
import Tree from './Tree';
import Header from './Header';
import TableFilter from './TableFilter';
import Clock from './Clock';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

export default {
  install(Vue) {
    Vue.component('AppAvatar', Avatar);
    Vue.component('AppField', Field);
    Vue.component('AppIconBtn', IconButton);
    Vue.component('AppForm', Form);
    Vue.component('AppLoader', Loader);
    Vue.component('AppTree', Tree);
    Vue.component('AppHeader', Header);
    Vue.component('AppModal', Modal);
    Vue.component('TableFilter', TableFilter);
    Vue.component('AppClock', Clock);
    Vue.component('VuePerfectScrollbar', VuePerfectScrollbar);
  }
};
