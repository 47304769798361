import Vue from 'vue';
import Vuetify from 'vuetify';

import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css';
import 'assets/scss/style.scss';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: localStorage.enableDarkMode && localStorage.enableDarkMode === 'true' ? localStorage.enableDarkMode : false,
    themes: {
      light: {
        primary: localStorage.theme && JSON.parse(localStorage.theme).primaryColor ?
          JSON.parse(localStorage.theme).primaryColor : '#ad3b28',
        secondary: localStorage.theme && JSON.parse(localStorage.theme).secondaryColor ?
          JSON.parse(localStorage.theme).secondaryColor : '#6c757d',
        background: '#fafafa',
        text: '#616161',
        accent: '#82b1ff',
        success: '#55ce63',
        danger: '#f62d51',
        info: '#009efb',
        warning: '#ffbc34',
        muted: '#8e8e8e',
        error: '#f62d51',
        highlight: '#272727'
      },
      dark: {
        primary: localStorage.theme && JSON.parse(localStorage.theme).primaryColor ?
          JSON.parse(localStorage.theme).primaryColor : '#ad3b28',
        secondary: localStorage.theme && JSON.parse(localStorage.theme).secondaryColor ?
          JSON.parse(localStorage.theme).secondaryColor : '#6c757d',
        background: '#272727',
        text: '#fafafa',
        accent: '#82b1ff',
        success: '#55ce63',
        danger: '#f62d51',
        info: '#009efb',
        warning: '#ffbc34',
        muted: '#8e8e8e',
        error: '#f62d51',
        highlight: '#272727'
      }
    }
  }
});
