export const industries = [
  'ACCOUNTING',
  'AIRLINES_AVIATION',
  'ALTERNATIVE_DISPUTE_RESOLUTION',
  'ALTERNATIVE_MEDICINE',
  'ANIMATION',
  'APPAREL_FASHION',
  'ARCHITECTURE_PLANNING',
  'ARTS_CRAFTS',
  'AUTOMOTIVE',
  'AVIATION_AND_AEROSPACE',
  'BANKING',
  'BIOTECHNOLOGY',
  'BROADCAST_MEDIA',
  'BUILDING_MATERIALS',
  'BUSINESS_SUPPLIES_EQUIPMENT',
  'CAPITAL_MARKETS',
  'CHEMICALS',
  'CIVIC_AND_SOCIAL_ORGANIZATION',
  'CIVIL_ENGINEERING',
  'COMMERCIAL_REAL_ESTATE',
  'COMPUTER_GAMES',
  'COMPUTER_HARDWARE',
  'COMPUTER_NETWORK_SECURITY',
  'COMPUTER_NETWORKING',
  'COMPUTER_SOFTWARE',
  'CONSTRUCTION',
  'CONSUMER_ELECRONICS',
  'CONSUMER_GOODS',
  'CONSUMER_SERVICES',
  'COSMETICS',
  'DAIRY',
  'DEFENSE_SPACE',
  'DESIGN',
  'E_LEARNING',
  'EDUCATION_MANAGEMENT',
  'ELECTRICAL_ELECTRONIC_MANUFACTURING',
  'ENTERTAINMENT',
  'ENVIROMENTAL_SERVICES',
  'EVENTS_SERVICES',
  'EXECUTIVE_OFFICE',
  'FACILITIES_SERVICES',
  'FARMING',
  'FINANCIAL_SERVICES',
  'FINE_ART',
  'FISHERY',
  'FOOD_BEVERAGES',
  'FOOD_PRODUCTION',
  'FUND_RAISING',
  'FURNITURE',
  'GAMBLING_CASINOS',
  'GLASS_CERAMICS_CONCRETE',
  'GOVERNMENT_ADMINISTRATION',
  'GOVERNMENT_RELATIONS',
  'GRAPHIC_DESIGN',
  'HEALTH_WELNESS_FITNESS',
  'HIGHER_EDUCATION',
  'HOSPITAL_HEALTH_CARE',
  'HOSPITALITY',
  'HUMAN_RESOURCES',
  'IMPORT_AND_EXPORT',
  'INDIVIDUAL_FAMILY_SERVICES',
  'INDUSTRIAL_AUTOMATION',
  'INFORMATION_SERVICES',
  'INFORMATION_TECHNOLOGY_AND_SERVICES',
  'INSURANCE',
  'INTERNATIONAL_AFFAIRS',
  'INTERNATIONAL_TRADE_AND_DEVELOPMENT',
  'INTERNET',
  'INVESTMEND_MANAGEMENT',
  'INVESTMENT_BANKING',
  'JUDICIARY',
  'LAW_ENFORCEMENT',
  'LAW_PRACTICE',
  'LEGAL_SERVICES',
  'LEGISLATIVE_OFFICE',
  'LEISURE_TRAVEL_TOURISM',
  'LIBRARIES',
  'LOGISTICS_SUPPLY_CHAIN',
  'LUXURY_GOODS_JEWELRY',
  'MACHINERY',
  'MANAGEMENT_CONSULTING',
  'MARITIME',
  'MARKET_RESEARCH',
  'MARKETING_ADVERTISING',
  'MECHANICAL_OR_INDUSTRIAL_ENGINEERING',
  'MEDIA_PRODUCTION',
  'MEDICAL_DEVICE',
  'MEDICAL_PRACTICE',
  'MENTAL_HEALTH_CARE',
  'MILITARY',
  'MINING_METALS',
  'MOTION_PICTURES_FILM',
  'MUSEUMS_AND_INSTITUTIONS',
  'MUSIC',
  'NANOTECHNOLOGY',
  'NEWSPAPERS',
  'NON_PROFIT_ORGANIZATION_MANAGEMENT',
  'OIL_ENERGY',
  'ONLINE_MEDIA',
  'OUTSOURCING_OFFSHORING',
  'PACKAGE_FREIGHT_DELIVERY',
  'PACKAGING_AND_CONTAINERS',
  'PAPER_FOREST_PRODUCTS',
  'PERFORMING_ARTS',
  'PHARMACEUTICALS',
  'PHILANTHROPY',
  'PHOTOGRAPHY',
  'PLASTICS',
  'POLITICAL_ORGANIZATION',
  'PRIMARY_SECONDARY_EDUCATION',
  'PRINTING',
  'PROFESSIONAL_TRAINING_COACHING',
  'PROGRAM_DEVELOPMENT',
  'PUBLIC_POLICY',
  'PUBLIC_RELATIONS_AND_COMMUNICATIONS',
  'PUBLIC_SAFETY',
  'PUBLISHING',
  'RAILROAD_MANUFACTURE',
  'RANCHING',
  'REAL_ESTATE',
  'RECREATIONAL_FACILITIES_SERVICES',
  'RELIGIOUS_INSTITUTIONS',
  'RENEWABLES_ENVIRNOMENT',
  'RESEARCH',
  'RESTAURANTS',
  'RETAIL',
  'SECURITY_INVESTIGATIONS',
  'SEMICONDUCS',
  'SHIPBUILDING',
  'SPORTING_GOODS',
  'STAFFING_RECRUITING',
  'SUPERMARKETS',
  'TELECOMMUNICATIONS',
  'TEXTILES',
  'THINK_THANKS',
  'TOBACCO',
  'TRANSLATION_LOCALISATION',
  'TRANSPORTATION_TRUCKING_RAILROAD',
  'UTILITIES',
  'VENTURE_CAPITAL_PRIVATE_EQUITY',
  'VETERINARY',
  'WAREHOUSING',
  'WHOLESALE',
  'WINE_SPIRITS',
  'WIRELESS',
  'WRITING_EDITING'
];
