import {User} from './user';
import _ from 'lodash';
import moment from 'moment';
/**
 * A class that can return the Goal model
 */
export class Goal {
  /** @param {Object} goal FastJson object. */
  constructor(goal = {}) {
    this.id = goal.id || null;
    if (goal.attributes) {
      this.title = goal.attributes.title || null;
      this.description = goal.attributes.description || null;
      this.startDate = moment(goal.attributes.start_date).format('DD MMM YYYY');
      this.endDate = moment(goal.attributes.end_date).format('DD MMM YYYY');
      this.assignee = goal.attributes.assignee ? new User(goal.attributes.assignee) : null;
      this.reviewer = goal.attributes.reviewer ? new User(goal.attributes.reviewer) : null;
      this.isPrivate = goal.attributes.is_private || null;
      this.status = goal.attributes.status || null;
      this.progress = goal.attributes.progress || null;
      this.duration = goal.attributes.duration || null;
      this.state = goal.attributes.state || null;
      this.supportingDocument = _.isEmpty(goal.attributes.supporting_document) ? null :
        goal.attributes.supporting_document.url;
      this.supportingDocumentName = _.isEmpty(goal.attributes.supporting_document) ? null :
        goal.attributes.supporting_document.filename;
    }
  }
}
