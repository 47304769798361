export {permissionsList} from './permissions';
export {industries} from './industries';

export const languages = {
  ar: {
    name: 'ع',
    locale: 'ar',
    i18nLocale: 'ar-EG'
  },
  en: {
    name: 'EN',
    locale: 'en',
    i18nLocale: 'en-US'
  }
};

export const userRoles = {
  ADMIN: 'admin',
  EMPLOYEE: 'employee'
};

export const treeSelectOptions = {
  ALL: 'ALL',
  BRANCH_PRIORITY: 'BRANCH_PRIORITY',
  LEAF_PRIORITY: 'LEAF_PRIORITY',
  ALL_WITH_INDETERMINATE: 'ALL_WITH_INDETERMINATE'
};

export const employeeEstOptions = [
  {key: '0_20', value: '0-20'},
  {key: '20_50', value: '20-50'},
  {key: '50_100', value: '50-100'},
  {key: '100_1000', value: '100-1000'},
  {key: '1000_more', value: '1000+'}
];

export const availablePermissions = {
  0: 'MANAGE_LEAVE_REQUESTS',
  1: 'MANAGE_GOALS'
};
export const leaveTables = {
  MANAGER_PENDING_REQUESTS: 'MANAGER_PENDING_REQUESTS',
  MANAGER_HISTORY_REQUESTS: 'MANAGER_HISTORY_REQUESTS',
  USER_HISTORY_REQUESTS: 'USER_HISTORY_REQUESTS'
};

export const goalTables = {
  MANAGER_PENDING_REQUESTS: 'MANAGER_PENDING_REQUESTS',
  USER_GOALS: 'USER_GOALS',
  OTHER_GOALS: 'OTHER_GOALS'
};

export const leaveUnits = {
  FULL_DAY: 'full_day',
  HALF_DAY: 'half_day',
  HOURS: 'hours'
};

export const assetTypes = {
  LAPTOP: 'LAPTOP',
  PC: 'PC',
  SCREEN: 'SCREEN',
  MOBILE: 'MOBILE',
  TABLET: 'TABLET'
};

export const userLeaveUnits = {
  FULL_DAY: 'full_day',
  HALF_DAY_MORNING: 'half_day_morning',
  HALF_DAY_EVENING: 'half_day_evening',
  HOURS: 'hours'
};

export const leaveStatus = {
  PENDING: 'pending',
  APPROVED: 'approved',
  REJECTED: 'rejected',
  CANCELED: 'canceled',
  PARTIALLY_APPROVED: 'partially_approved'
};

export const calendar = {
  BIRTHDAYS: 'BIRTHDAYS',
  WORK_ANNIVERSARIES: 'WORK_ANNIVERSARIES',
  LEAVES: 'LEAVES',
  GOOGLE_EVENTS: 'GOOGLE_EVENTS',
  OFFICE_HOLIDAYS: 'OFFICE_HOLIDAYS'
};

export const goalState = {
  CREATED: 'created',
  ACHIEVED: 'achieved',
  IN_PROGRESS: 'in_progress'
};

export const goalStatus = {
  PENDING: 'pending',
  APPROVED: 'approved',
  REJECTED: 'rejected'
};
