/**
 * A class that can return the jira issue
 */
export class JiraTicket {
  /** @param {Object} ticket jira issue object. */
  constructor(ticket = {}) {
    this.id = ticket.id || null;
    this.key = ticket.key || null;
    if (ticket.fields) {
      this.summary = ticket.fields.summary || null;
      this.description = ticket.fields.description || null;
      // FIXME: Get the project name in the backend in a separate endpoint
      this.project = ticket.key ? ticket.key.split('-')[0] : null;
    }
  }
}
