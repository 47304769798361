/**
 * A class that can return the Team model
 */
export class Team {
  /** @param {Object} team FastJson object. */
  constructor(team = {}) {
    this.id = team.id || null;
    if (team.attributes) {
      this.name = team.attributes.name || null;
      this.duties = team.attributes.duties || null;
    } else {
      this.name = team.name || null;
      this.duties = team.duties || null;
    }
  }
}
