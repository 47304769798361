/**
 * A class that can return the Leave Details
 */
export class LeaveDetails {
  /** @param {Object} leave FastJson object. */
  constructor(leave = {}) {
    this.hours = parseFloat(leave.hours).toFixed(2) || 0;
    this.convention = leave.convention || null;
  }
}
