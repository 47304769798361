import {List, ListParams} from 'models/list';
import {User} from 'models/user';
import {Permission} from 'models/permission';

const state = {
  users: new List(),
  user: new User(),
  userPermissions: [],
  userSupervisors: []
};

const getters = {
  users: (state) => state.users,
  user: (state) => state.user,
  userPermissions: (state) => state.userPermissions,
  userSupervisors: (state) => state.userSupervisors
};

const actions = {
  async getUsers({commit}, options) {
    commit('setLoading', true);
    const response = await this.$app.$http.get('/users', {params: new ListParams(options)});
    if (response.status === 200) {
      commit('setUsers', response.data);
      commit('setLoading', false);
    } else {
      commit('setLoading', false);
      this.$app.$snotify.error(this.$app.$t('messages.error.internalServerError'));
    }
  },
  async getUser({commit, rootState}, {uuid, options}) {
    commit('setLoading', true);
    uuid = uuid === 'me' ? rootState.session.currentUser.id : uuid;
    const response = await this.$app.$http.get(`/users/${uuid}`, {params: new ListParams(options)});
    if (response.status === 200) {
      commit('setUser', response.data);
      commit('setLoading', false);
    } else {
      commit('setLoading', false);
      this.$app.$router.push({name: 'Users'});
      this.$app.$snotify.error(this.$app.$t('messages.error.notFound',
        {entity: this.$app.$tc('models.user.entity', 1)}));
    }
  },
  async followUser({commit, rootState}, {uuid}) {
    commit('setLoading', true);
    const response = await this.$app.$http.post(`/users/${uuid}/follow`);
    if (response.status === 200) {
      commit('setLoading', false);
      this.$app.$snotify.success(this.$app.$t('messages.success.created', {
        entity: this.$app.$tc('models.follow.entity', 1)
      }));
    } else {
      commit('setLoading', false);
      this.$app.$snotify.error(this.$app.$t('messages.error.notFound',
        {entity: this.$app.$tc('models.user.entity', 1)}));
    }
  },
  async unfollowUser({commit, rootState}, {uuid}) {
    commit('setLoading', true);
    const response = await this.$app.$http.delete(`/users/${uuid}/unfollow`);
    if (response.status === 200) {
      commit('setLoading', false);
      this.$app.$snotify.success(this.$app.$t('messages.success.deleted', {
        entity: this.$app.$tc('models.follow.entity', 1)
      }));
    } else {
      commit('setLoading', false);
      this.$app.$snotify.error(this.$app.$t('messages.error.notFound',
        {entity: this.$app.$tc('models.user.entity', 1)}));
    }
  },
  async inviteUser({commit}, user) {
    commit('setLoading', true);
    const response = await this.$app.$http.post('/invite_user', user);
    if (response.status === 200) {
      commit('setUser', response.data);
      commit('setLoading', false);
      this.$app.$snotify.success(this.$app.$t('messages.success.invitation'));
      this.$app.$router.push({
        name: 'EditProfile',
        params: {
          uuid: response.data.id
        }
      });
    } else {
      commit('setLoading', false);
    }
    return response;
  },
  async updateUser({commit, rootState}, data) {
    commit('setLoading', true);
    const userId = data.userId === 'me' ? rootState.session.currentUser.id : data.userId;
    const response = await this.$app.$http.put(`/users/${userId}`, data.userData);
    if (response.status === 200) {
      if (`${userId}` === localStorage.uuid) {
        commit('setCurrentUserData', new User(response.data));
      }
      commit('setUser', response.data);
      commit('setLoading', false);
      this.$app.$snotify.success(this.$app.$t('messages.success.updated', {
        entity: this.$app.$tc('models.user.entity', 1)
      }));
    } else {
      commit('setLoading', false);
    }
    return response;
  },
  async getUserPermissions({commit, rootState}, data) {
    commit('setLoading', true);
    const uuid = data.uuid === 'me' ? rootState.session.currentUser.id : data.uuid;
    const response = await this.$app.$http.get(`/users/${uuid}/permissions`, {params: new ListParams(data.options)});
    if (response.status === 200) {
      commit('setUserPermissions', response.data);
      commit('setLoading', false);
    } else {
      commit('setLoading', false);
      this.$app.$snotify.error(this.$app.$t('messages.error.notFound',
        {entity: this.$app.$tc('models.user.entity', 1)}));
    }
  },
  async updateUserPermissions({commit, rootState}, data) {
    commit('setLoading', true);
    const uuid = data.userId === 'me' ? rootState.session.currentUser.id : data.userId;
    const response = await this.$app.$http.put(`/users/${uuid}/update_permissions`, data.permissions);
    if (response.status === 200) {
      commit('setLoading', false);
      this.$app.$snotify.success(this.$app.$t('messages.success.updated', {
        entity: this.$app.$tc('models.user.entity', 1)
      }));
    } else {
      commit('setLoading', false);
      this.$app.$snotify.error(this.$app.$t('messages.error.updated', {
        entity: this.$app.$tc('models.user.entity', 1)
      }));
    }
  },
  async getUserSupervisors({commit, rootState}, uuid) {
    commit('setLoading', true);
    uuid = uuid === 'me' ? rootState.session.currentUser.id : uuid;
    const response = await this.$app.$http.get(`/users/${uuid}/supervisors`);
    if (response.status === 200) {
      commit('setUserSupervisors', this.$app._.map(response.data, (supervisor) => new User(supervisor)));
      commit('setLoading', false);
    } else {
      commit('setLoading', false);
      this.$app.$snotify.error(this.$app.$t('messages.error.internalServerError'));
    }
  },
  async destroyUser({commit}, uuid) {
    commit('setLoading', true);
    const response = await this.$app.$http.delete(`/users/${uuid}`);
    if (response.status === 200) {
      commit('setLoading', false);
      this.$app.$snotify.success(this.$app.$t('messages.success.deleted',
        {entity: this.$app.$tc('models.user.entity', 1)}));
    } else {
      commit('setLoading', false);
      this.$app.$snotify.error(this.$app.$t('messages.error.deleted',
        {entity: this.$app.$tc('models.user.entity', 1)}));
    }
    return response.status;
  },
  async setLocale({commit}, data) {
    commit('setLoading', true);
    const response = await this.$app.$http.get(`/users/${data.uuid}/?locale=${data.locale}`);
    commit('setLoading', false);
    return response;
  }
};

const mutations = {
  setUser: (state, data) => state.user = new User(data),
  setUsers: (state, data) => {
    state.users = new List({items: data.items.map((user) => new User(user)), count: data.count});
  },
  setUserPermissions: (state, data) => {
    state.userPermissions = new List({
      items: data.items.map((permission) => new Permission(permission)),
      count: data.count
    });
  },
  setUserSupervisors: (state, data) => state.userSupervisors = data
};

export default {
  state,
  getters,
  actions,
  mutations
};
