<template>
  <v-app :style="cssProps">
    <vue-snotify />
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: 'App',
  computed: {
    cssProps() {
      const themeColors = {};
      Object.keys(this.$vuetify.theme.themes[this.$vuetify.theme.dark ? 'dark' : 'light']).forEach((color) => {
        themeColors[`--v-${color}`] = this.$vuetify.theme.themes[this.$vuetify.theme.dark ? 'dark' : 'light'][color];
      });
      return themeColors;
    }
  }
};
</script>
