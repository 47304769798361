import {permissionsList} from 'constants';

const state = {
  mainMenu: [{
    title: 'layout.mainMenu.home',
    icon: 'mdi-home-variant',
    link: {
      name: 'Home'
    }
  },
  {
    title: 'layout.mainMenu.calendar',
    icon: 'mdi-calendar',
    link: {
      name: 'Calendar'
    }
  },
  {
    title: 'layout.accountNavMenu.worklogs',
    icon: 'mdi-timer',
    link: {
      name: 'UserWorklogs',
      params: {
        uuid: 'me'
      }
    }
  },
  {
    title: 'layout.mainMenu.leaves',
    icon: 'mdi-beach',
    link: {},
    items: [{
      title: 'layout.accountNavMenu.requestNewLeave',
      icon: 'mdi-plus',
      link: {
        name: 'NewLeaveRequest',
        params: {
          uuid: 'me'
        }
      }
    },
    {
      title: 'layout.accountNavMenu.leaves',
      icon: 'mdi-account-tie',
      link: {
        name: 'UserLeaves',
        params: {
          uuid: 'me'
        }
      }
    },
    {
      title: 'layout.mainMenu.leaveRequests',
      icon: 'mdi-account-question',
      link: {
        name: 'LeavesRequests',
        requireAdminAuth: true,
        requireManagerPermission: true,
        permission: permissionsList['MANAGE_LEAVE_REQUESTS']
      }
    }]
  },
  {
    title: 'layout.mainMenu.goals',
    icon: 'mdi-bullseye-arrow',
    link: {},
    items: [{
      title: 'layout.accountNavMenu.newGoal',
      icon: 'mdi-plus',
      link: {
        name: 'NewGoal'
      }
    },
    {
      title: 'layout.accountNavMenu.goals',
      icon: 'mdi-account-tie',
      link: {
        name: 'UserGoals',
        params: {
          uuid: 'me'
        }
      }
    },
    {
      title: 'layout.mainMenu.otherGoals',
      icon: 'mdi-account-group',
      link: {
        name: 'OtherGoals'
      }
    },
    {
      title: 'layout.mainMenu.goalRequests',
      icon: 'mdi-account-question',
      link: {
        name: 'GoalRequests',
        requireAdminAuth: true,
        requireManagerPermission: true,
        permission: permissionsList['MANAGE_GOALS']
      }
    }]
  },
  {
    title: 'layout.mainMenu.profile',
    icon: 'mdi-account-tie',
    link: {},
    items: [{
      title: 'layout.accountNavMenu.documents',
      icon: 'mdi-text-box-multiple',
      link: {
        name: 'UserDocuments',
        params: {
          uuid: 'me'
        }
      }
    },
    {
      title: 'layout.accountNavMenu.assets',
      icon: 'mdi-laptop',
      link: {
        name: 'UserAssets',
        params: {
          uuid: 'me'
        }
      }
    },
    {
      title: 'layout.accountNavMenu.permissions',
      icon: 'mdi-account-lock',
      link: {
        name: 'Permissions',
        params: {
          uuid: 'me'
        }
      }
    }, {
      title: 'layout.accountNavMenu.supervisors',
      icon: 'mdi-account-supervisor',
      link: {
        name: 'Supervisors',
        params: {
          uuid: 'me'
        }
      }
    }
    ]
  },
  {
    title: 'layout.mainMenu.users',
    icon: 'mdi-account-multiple',
    link: {
      name: 'Users'
    }
  },
  {
    title: 'layout.mainMenu.kudos',
    icon: 'mdi-trophy-award',
    link: {},
    items: [{
      title: 'layout.accountNavMenu.requestNewKudos',
      icon: 'mdi-plus',
      link: {
        name: 'NewKudoRequest'
      }
    },
    {
      title: 'layout.mainMenu.kudos',
      icon: 'mdi-trophy-award',
      link: {
        name: 'Kudos'
      }
    },
    {
      title: 'layout.accountNavMenu.kudos',
      icon: 'mdi-account-tie',
      link: {
        name: 'UserKudos',
        params: {
          uuid: 'me'
        }
      }
    }
    ]
  },
  {
    title: 'layout.mainMenu.organizationChart',
    icon: 'mdi-sitemap',
    link: {
      name: 'OrganizationChart'
    }
  },
  {
    title: 'layout.mainMenu.company',
    icon: 'mdi-briefcase',
    link: {
      requireAdminAuth: true
    },
    items: [{
      title: 'layout.mainMenu.offices',
      icon: 'mdi-home-city-outline',
      link: {
        name: 'Offices',
        requireAdminAuth: true
      }
    },
    {
      title: 'layout.mainMenu.departments',
      icon: 'mdi-office-building',
      link: {
        name: 'Departments',
        requireAdminAuth: true
      }
    },
    {
      title: 'layout.mainMenu.teams',
      icon: 'mdi-account-supervisor-circle',
      link: {
        name: 'Teams',
        requireAdminAuth: true
      }
    },
    {
      title: 'layout.mainMenu.officialHolidays',
      icon: 'mdi-calendar-star',
      link: {
        name: 'OfficialHolidays',
        requireAdminAuth: true
      }
    },
    {
      title: 'layout.mainMenu.leaves',
      icon: 'mdi-beach',
      link: {
        name: 'Leaves',
        requireAdminAuth: true
      }
    },
    {
      title: 'layout.mainMenu.assets',
      icon: 'mdi-laptop',
      link: {
        name: 'Assets',
        requireAdminAuth: true
      }
    }]
  },
  {
    title: 'layout.mainMenu.settings',
    icon: 'mdi-cog',
    link: {
      requireAdminAuth: true
    },
    items: [{
      title: 'layout.mainMenu.companyProfile',
      icon: 'mdi-briefcase-edit',
      link: {
        name: 'CompanySettings',
        requireAdminAuth: true
      }
    },
    {
      title: 'layout.mainMenu.companyTheme',
      icon: 'mdi-palette-outline',
      link: {
        name: 'CompanyTheme',
        requireAdminAuth: true
      }
    },
    {
      title: 'layout.mainMenu.integrations',
      icon: 'mdi-shield-link-variant-outline',
      link: {
        name: 'Integrations',
        requireAdminAuth: true
      }
    }]
  }],

  userMenu: [{
    title: 'layout.userMenu.profile',
    link: {
      name: 'EditProfile',
      currentUserOnly: true,
      requireAdminAuth: true
    }
  },
  {
    title: 'layout.userMenu.work',
    link: {
      name: 'EditWorkInfo',
      requireCurrentUserOrAdmin: true
    }
  },
  {
    title: 'layout.userMenu.password',
    link: {
      name: 'EditPassword',
      currentUserOnly: true,
      requireAdminAuth: true
    }
  },
  {
    title: 'layout.userMenu.leaves',
    link: {
      name: 'UserLeaves',
      currentUserOnly: true,
      requireAdminAuth: true
    }
  },
  {
    title: 'layout.userMenu.kudos',
    link: {
      name: 'UserKudos',
      currentUserOnly: true
    }
  },
  {
    title: 'layout.userMenu.goals',
    link: {
      name: 'UserGoals',
      currentUserOnly: true,
      requireAdminAuth: true
    }
  },
  {
    title: 'layout.userMenu.documents',
    link: {
      name: 'UserDocuments',
      currentUserOnly: true,
      requireAdminAuth: true
    }
  },
  {
    title: 'layout.userMenu.assets',
    link: {
      name: 'UserAssets',
      currentUserOnly: true,
      requireAdminAuth: true
    }
  },
  {
    title: 'layout.userMenu.permissions',
    link: {
      name: 'Permissions',
      currentUserOnly: true,
      requireAdminAuth: true
    }
  }, {
    title: 'layout.userMenu.supervisors',
    link: {
      name: 'Supervisors',
      currentUserOnly: true,
      requireAdminAuth: true
    }
  }],

  accountNavMenu: [{
    title: 'layout.accountNavMenu.publicProfile',
    icon: 'mdi-account',
    link: {
      name: 'UserPublicProfile',
      params: {
        uuid: 'me'
      }
    }
  },
  {
    divider: true,
    inset: false
  },
  {
    title: 'layout.accountNavMenu.editProfile',
    icon: 'mdi-account-edit',
    link: {
      name: 'EditProfile',
      params: {
        uuid: 'me'
      }
    }
  },
  {
    title: 'layout.accountNavMenu.editWorkInfo',
    icon: 'mdi-account-tie',
    link: {
      name: 'EditWorkInfo',
      params: {
        uuid: 'me'
      }
    }
  },
  {
    title: 'layout.accountNavMenu.editPassword',
    icon: 'mdi-account-key',
    link: {
      name: 'EditPassword',
      params: {
        uuid: 'me'
      }
    }
  },
  {
    divider: true,
    inset: false
  }],
  miniSidebar: false
};

const getters = {
  mainMenu: (state) => (state.mainMenu),
  userMenu: (state) => (state.userMenu),
  accountNavMenu: (state) => (state.accountNavMenu),
  miniSidebar: (state) => (state.miniSidebar)
};

const actions = {
  toggleSidebar({commit}) {
    commit('toggleSidebar');
  }
};

const mutations = {
  toggleSidebar: (state) => (state.miniSidebar = !state.miniSidebar)
};

export default {
  state,
  getters,
  actions,
  mutations
};
