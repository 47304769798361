import {Calendar} from 'models/calendar';
import {CalendarEvent} from 'models/event';

const state = {
  calendars: [],
  calendar: [],
  events: []
};

const getters = {
  calendars: (state) => state.calendars,
  calendar: (state) => state.calendar,
  calendarEvents: (state) => state.events
};

const actions = {
  async getCalendars({commit}) {
    const response = await this.$app.$http.get('/calendars_configurations');
    if (response.status === 200) {
      commit('setCalendars', response.data);
    } else {
      this.$app.$snotify.error(this.$app.$t('messages.error.internalServerError'));
    }
  },
  async updateCalendar({commit}, data) {
    commit('setLoading', true);
    const response = await this.$app.$http.put(`/calendars_configurations/${data.id}`, data.form);
    if (response.status === 200) {
      commit('setCalendar', {data: response.data, key: data.key});
      commit('setLoading', false);
    } else {
      this.$app.$snotify.error(this.$app.$t('messages.error.internalServerError'));
      commit('setLoading', false);
    }
    return response.status;
  },
  async updateEvents({commit}, events) {
    commit('setEvents', events);
  },
  async getEvents({rootState, commit}, options) {
    const response = await this.$app.$http.get(`/companies/${rootState.session.company.id}/events`, {params: options});
    if (response.status === 200) {
      const events = response.data.events.map((event) => new CalendarEvent({
        ...event,
        attributes: {
          ...event.attributes,
          color: event.attributes.color ? event.attributes.color : rootState.session.theme.primaryColor
        }
      }));
      commit('setEvents', events);
    } else {
      this.$app.$snotify.error(this.$app.$t('messages.error.internalServerError'));
    }
  }
};

const mutations = {
  setCalendars: function(state, data) {
    state.calendars = this.$app._.keyBy(data.items.map((calendar) => new Calendar(calendar)), 'key');
  },
  setCalendar: (state, {data, key}) => {
    state.calendars[key] = new Calendar(data);
    state.events = state.events.map((calEvent) => ({
      ...calEvent,
      backgroundColor: state.calendars[calEvent.groupId].color,
      borderColor: state.calendars[calEvent.groupId].color
    }));
  },
  setEvents: (state, data) => state.events = data
};

export default {
  state,
  getters,
  actions,
  mutations
};
