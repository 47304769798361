<template>
<div id="loader-wrapper" v-if="loading">
  <div id="loader">
    <div class="loader-ellips">
      <span class="loader-ellips__dot"></span>
      <span class="loader-ellips__dot"></span>
      <span class="loader-ellips__dot"></span>
      <span class="loader-ellips__dot"></span>
    </div>
  </div>
</div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'Loader',
  computed: mapGetters(['loading'])
};
</script>
