/**
 * A class that can return the Kudo model
 */
export class Kudo {
/** @param {Object} kudo FastJson object. */
  constructor(kudo = {}) {
    this.id = kudo.id || null;
    if (kudo.attributes) {
      this.title = kudo.attributes.title || null;
      this.description = kudo.attributes.description || null;
      this.badge = kudo.attributes.badge || null;
      this.creator_id = kudo.attributes.creator_id || null;
      this.assignee_id = kudo.attributes.assignee_id || null;
      this.assignee_name = kudo.attributes.assignee_name || null;
      this.creator_name = kudo.attributes.creator_name || null;
    } else {
      this.title = kudo.title || null;
      this.description = kudo.description || null;
      this.badge = kudo.badge || null;
      this.creator_id = kudo.creator_id || null;
      this.assignee_id = kudo.assignee_id || null;
      this.assignee_name = kudo.assignee_name || null;
      this.creator_name = kudo.creator_name || null;
    }
  }
}
