export default [
  {
    path: '/sessions',
    name: 'Session',
    redirect: '/login',
    component: () => import('views/session/index'),
    children: [
      {
        path: '/login',
        name: 'Login',
        component: () => import('views/session/login')
      },
      {
        path: '/register',
        name: 'Register',
        component: () => import('views/session/register')
      },
      {
        path: '/register-success',
        name: 'RegisterSuccess',
        component: () => import('views/session/registrationCompleted')
      },
      {
        path: '/set-password',
        name: 'SetPassword',
        component: () => import('views/session/setPassword')
      },
      {
        path: '/reset-password',
        name: 'ResetPassword',
        component: () => import('views/session/resetPassword')
      }
    ]
  }
];
