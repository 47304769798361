import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
/**
 * A class that can return the Worklog model
 */
export class Worklog {
  /** @param {Object} worklog FastJson object. */
  constructor(worklog = {}) {
    momentDurationFormatSetup(moment);
    this.id = worklog.id || null;
    if (worklog.attributes) {
      this.jiraTask = worklog.attributes.jira_task || null;
      this.jiraUrl = worklog.attributes.jira_url || null;
      this.description = worklog.attributes.description || null;
      this.durationInSec = worklog.attributes.duration || null;
      this.duration = moment.duration(this.durationInSec, 'seconds').format('hh:mm:ss', {trim: false});
      this.isSynced = worklog.attributes.is_synced || null;
      this.startTime = worklog.attributes.start_time ? moment(worklog.attributes.start_time).format('hh:mm A') : null;
      this.endTime = worklog.attributes.end_time ? moment(worklog.attributes.end_time).format('hh:mm A') : null;
      this.date = worklog.attributes.date || null;
    }
  }
}
