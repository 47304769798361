import {User} from 'models/user';

const state = {};
const getters = {};
const actions = {
  async authenticateGoogleAccount({commit}, data) {
    commit('setLoading', true);
    const response = await this.$app.$http.get('/calendar', {
      params: data
    });
    if (response.status === 200) {
      commit('setCurrentUserData', new User(response.data));
      commit('setLoading', false);
    } else {
      commit('setLoading', false);
      this.$app.$snotify.error(this.$app.$t('messages.error.googleSync'));
    }
    return response.status;
  }
};
const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations
};
