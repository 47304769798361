import {User} from './user';
import {Leave} from './leave';
import _ from 'lodash';
import moment from 'moment';
import {leaveUnits} from 'constants';

/**
 * A class that can return the Leaves Request model
 */
export class LeaveRequest {
  /** @param {Object} leavesRequest FastJson object. */
  constructor(leavesRequest = {}) {
    this.id = leavesRequest.id || null;
    if (leavesRequest.attributes) {
      this.canEdit = leavesRequest.attributes.can_edit || false;
      this.leave = new Leave(leavesRequest.attributes.leave);
      this.userLeaveId = leavesRequest.attributes.user_leave_id;
      this.user = leavesRequest.attributes.user? new User(leavesRequest.attributes.user) : null;
      this.status = leavesRequest.attributes.status || null;
      this.unit = leavesRequest.attributes.unit || null;
      this.duration = leavesRequest.attributes.duration || null;
      this.note = leavesRequest.attributes.note || null;
      this.dates = leavesRequest.attributes.dates ?
        _.map(leavesRequest.attributes.dates, (dateDetails) => {
          return {
            date: dateDetails.date,
            dateWithTimezone: moment(dateDetails.date).format('ddd, DD MMM') || null,
            status: dateDetails.status,
            managers: _.map(dateDetails.details, (detail) => {
              return {
                id: detail.manager ? detail.manager.id : null,
                manager: detail.manager ? new User(detail.manager) : null,
                response: detail.response
              };
            })
          };
        }) : [];
      this.startDate = leavesRequest.attributes.start_date || null;
      this.endDate = leavesRequest.attributes.end_date || null;
      this.startDateWithTimezone = moment(leavesRequest.attributes.start_date).format('ddd, DD MMM') || null;
      this.endDateWithTimezone = moment(leavesRequest.attributes.end_date).format('ddd, DD MMM') || null;
      if (this.unit === leaveUnits.HOURS && leavesRequest.attributes.dates) {
        this.startTime = moment(this.startDate).format('hh:mm A');
        this.endTime = moment(this.endDate).format('hh:mm A');
      }
    }
  }
}
