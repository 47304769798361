import Vue from 'vue';
import vuetify from './plugins/vuetify';
import VuetifyConfirm from 'vuetify-confirm';
import VueMask from 'v-mask';
import VueSlimScroll from 'vue-slimscroll';
import Snotify, {SnotifyPosition} from 'vue-snotify';
import VueLodash from 'vue-lodash';
import lodash from 'lodash';
import VueMoment from 'vue-moment';
import moment from 'moment';
import router from './router';
import store from './store';
import i18n from './plugins/i18n';
import axios from './plugins/axios';
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
import App from './app.vue';
import SharedComponents from './components/shared';
import 'assets/scss/style.scss';
import './helpers/mixins';
import './helpers/filters';

Vue.config.productionTip = false;

Vue.use(VueLodash, {lodash: lodash});
Vue.use(VueMoment, {moment});
Vue.use(VueSlimScroll);
Vue.use(VueMask);
Vue.use(VueTelInputVuetify, {vuetify});
Vue.use(SharedComponents);
Vue.use(Snotify, {toast: {timeout: 3000, position: SnotifyPosition.rightBottom}});
Vue.use(VuetifyConfirm, {
  vuetify,
  color: 'warning',
  icon: 'warning',
  title: 'Warning',
  width: 350,
  property: '$confirm'
});

const app = new Vue({
  i18n,
  router,
  store,
  axios,
  vuetify,
  render: (h) => h(App)
});

store.$app = app;

app.$mount('#app');
