import {List, ListParams} from 'models/list';
import {Department} from 'models/department';

const state = {
  departments: new List()
};

const getters = {
  departments: (state) => state.departments
};

const actions = {
  async getDepartments({commit}, options) {
    commit('setLoading', true);
    const response = await this.$app.$http.get('/departments', {params: new ListParams(options)});
    if (response.status === 200) {
      commit('setDepartments', response.data);
      commit('setLoading', false);
    } else {
      commit('setLoading', false);
      this.$app.$snotify.error(this.$app.$t('messages.error.internalServerError'));
    }
  },
  async createDepartment({commit}, data) {
    commit('setLoading', true);
    const response = await this.$app.$http.post('/departments', data);
    if (response.status === 200) {
      commit('setLoading', false);
      this.$app.$snotify.success(this.$app.$t('messages.success.updated', {
        entity: this.$app.$tc('models.department.entity', 1)
      }));
    } else {
      commit('setLoading', false);
    }
    return response;
  },
  async updateDepartment({commit}, data) {
    commit('setLoading', true);
    const response = await this.$app.$http.put(`/departments/${data.departmentId}`, data.form);
    if (response.status === 200) {
      commit('setLoading', false);
      this.$app.$snotify.success(this.$app.$t('messages.success.updated', {
        entity: this.$app.$tc('models.department.entity', 1)
      }));
    } else {
      commit('setLoading', false);
    }
    return response;
  },
  async destroyDepartment({commit}, id) {
    commit('setLoading', true);
    const response = await this.$app.$http.delete(`/departments/${id}`);
    if (response.status === 200) {
      commit('setLoading', false);
      this.$app.$snotify.success(this.$app.$t('messages.success.deleted',
        {entity: this.$app.$tc('models.department.entity', 1)}));
    } else {
      commit('setLoading', false);
      this.$app.$snotify.error(this.$app.$t('messages.error.deleted',
        {entity: this.$app.$tc('models.department.entity', 1)}));
    }
    return response.status;
  }
};

const mutations = {
  setDepartments: (state, data) => {
    state.departments = new List({
      items: data.items.map((department) => new Department(department)),
      count: data.count
    });
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
