import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from '../translations';
import store from '../store';

Vue.use(VueI18n);

export default new VueI18n({
  locale: store.getters.currentLanguage.i18nLocale,
  fallbackLocale: 'en-US',
  messages
});
