import {List, ListParams} from 'models/list';
import {Team} from 'models/team';

const state = {
  teams: new List()
};

const getters = {
  teams: (state) => state.teams
};

const actions = {
  async getTeams({commit}, data) {
    commit('setLoading', true);
    const response = await this.$app.$http.get('/teams', {
      params: new ListParams(data)
    });
    if (response.status === 200) {
      commit('setTeams', response.data);
      commit('setLoading', false);
    } else {
      commit('setLoading', false);
      this.$app.$snotify.error(this.$app.$t('messages.error.internalServerError'));
    }
  },
  async createTeam({commit}, data) {
    commit('setLoading', true);
    const response = await this.$app.$http.post('/teams', data);
    if (response.status === 200) {
      commit('setLoading', false);
      this.$app.$snotify.success(this.$app.$t('messages.success.updated', {
        entity: this.$app.$tc('models.team.entity', 1)
      }));
    } else {
      commit('setLoading', false);
    }
    return response;
  },
  async updateTeam({commit}, data) {
    commit('setLoading', true);
    const response = await this.$app.$http.put(`/teams/${data.teamId}`, data.form);
    if (response.status === 200) {
      commit('setLoading', false);
      this.$app.$snotify.success(this.$app.$t('messages.success.updated', {
        entity: this.$app.$tc('models.team.entity', 1)
      }));
    } else {
      commit('setLoading', false);
    }
    return response;
  },
  async destroyTeam({commit}, id) {
    commit('setLoading', true);
    const response = await this.$app.$http.delete(`/teams/${id}`);
    if (response.status === 200) {
      commit('setLoading', false);
      this.$app.$snotify.success(this.$app.$t('messages.success.deleted', {
        entity: this.$app.$tc('models.team.entity', 1)
      }));
    } else {
      commit('setLoading', false);
      this.$app.$snotify.error(this.$app.$t('messages.error.deleted', {
        entity: this.$app.$tc('models.team.entity', 1)
      }));
    }
    return response.status;
  }
};

const mutations = {
  setTeams: (state, data) => {
    state.teams = new List({
      items: data.items.map((team) => new Team(team)),
      count: data.count
    });
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
