import {permissionsList} from 'constants';

export default [{
  path: '/',
  name: 'MainPanel',
  redirect: '/home',
  component: () => import('views/main/index'),
  children: [{
    path: '/home',
    name: 'Home',
    component: () => import('views/main/home')
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: () => import('views/main/calendar')
  },
  {
    path: '/users/:uuid/worklogs',
    name: 'UserWorklogs',
    component: () => import('views/main/worklogs'),
    meta: {
      requireCurrentUserOrAdmin: true
    }
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('views/main/users/index')
  },
  {
    path: '/organization-chart',
    name: 'OrganizationChart',
    component: () => import('views/main/organizationChart')
  },
  {
    path: '/users/invitation',
    name: 'UserInvitation',
    component: () => import('views/main/users/invitation'),
    meta: {
      requireAdminAuth: true
    }
  },
  {
    path: '/users/:uuid',
    name: 'UserPublicProfile',
    component: () => import('views/main/users/show')
  },
  {
    path: '/settings/profile',
    name: 'CompanySettings',
    component: () => import('views/main/settings/profile'),
    meta: {
      requireAdminAuth: true
    }
  },
  {
    path: '/settings/theme',
    name: 'CompanyTheme',
    component: () => import('views/main/settings/theme'),
    meta: {
      requireAdminAuth: true
    }
  },
  {
    path: '/settings/integrations',
    name: 'Integrations',
    component: () => import('views/main/settings/integrations'),
    meta: {
      requireAdminAuth: true
    }
  },
  {
    path: '/settings/helpDesks',
    name: 'HelpDesks',
    component: () => import('views/main/settings/helpDesks'),
    meta: {
      requireAdminAuth: true
    }
  },
  {
    path: '/company/offices',
    name: 'Offices',
    component: () => import('views/main/company/offices'),
    meta: {
      requireAdminAuth: true
    }
  },
  {
    path: '/company/departments',
    name: 'Departments',
    component: () => import('views/main/company/departments'),
    meta: {
      requireAdminAuth: true
    }
  },
  {
    path: '/company/teams',
    name: 'Teams',
    component: () => import('views/main/company/teams'),
    meta: {
      requireAdminAuth: true
    }
  },
  {
    path: '/company/leaves',
    name: 'Leaves',
    component: () => import('views/main/company/leaves'),
    meta: {
      requireAdminAuth: true
    }
  },
  {
    path: '/company/officialHolidays',
    name: 'OfficialHolidays',
    component: () => import('views/main/company/officialHolidays'),
    meta: {
      requireAdminAuth: true
    }
  },
  {
    path: '/requests/leaves',
    name: 'LeavesRequests',
    component: () => import('views/main/leaves/manage'),
    meta: {
      requireManagerPermission: true,
      permission: permissionsList['MANAGE_LEAVE_REQUESTS']
    }
  },
  {
    path: '/company/assets',
    name: 'Assets',
    component: () => import('views/main/company/assets'),
    meta: {
      requireAdminAuth: true
    }
  },
  {
    path: '/leaves/new',
    name: 'NewLeaveRequest',
    component: () => import('views/main/leaves/request')
  },
  {
    path: 'leaves/:id/edit',
    name: 'EditLeaveRequest',
    component: () => import('views/main/leaves/request')
  },
  {
    path: '/goals/new',
    name: 'NewGoal',
    component: () => import('views/main/goals/request')
  },
  {
    path: '/goals/:id/edit',
    name: 'EditGoal',
    component: () => import('views/main/goals/request')
  },
  {
    path: '/requests/goals',
    name: 'GoalRequests',
    component: () => import('views/main/goals/manage'),
    meta: {
      requireManagerPermission: true,
      permission: permissionsList['MANAGE_GOALS']
    }
  },
  {
    path: '/otherGoals',
    name: 'OtherGoals',
    component: () => import('views/main/goals/index')
  },
  {
    path: '/kudos',
    name: 'Kudos',
    component: () => import('views/main/kudos/index')
  },
  {
    path: '/kudos/new',
    name: 'NewKudoRequest',
    component: () => import('views/main/kudos/request')
  },
  {
    path: 'kudos/:id/edit',
    name: 'EditKudoRequest',
    component: () => import('views/main/kudos/request')
  }
  ]
}];
