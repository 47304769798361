export default {
  success: {
    authentication: 'Welcome back!',
    created: '{entity} was created succesfully!',
    updated: '{entity} was updated succesfully!',
    deleted: '{entity} was deleted succesfully!',
    invitation: 'Invitation sent successfully!',
    resetPassword: 'Please check you email to reset your password.',
    jiraSync: 'Worklogs are synced successfully!'
  },
  error: {
    // Server responses
    internalServerError: 'An error has occured!',
    forbidden: 'You don\'t have permission to access this resource',
    authentication: 'Invalid credentials',
    created: 'An error has occured while creating!',
    updated: 'An error has occured while updating!',
    domainTaken: 'Domain is already taken',
    deleted: 'An error has occured while deleting!',
    notFound: '{entity} is not found!',
    accountNotExists: 'Account doesn\'t exist',
    googleSync: 'Error occurs while syncing with google API',
    jiraSync: 'Error occurs while syncing with Jira API',
    jiraFetch: 'Error occurs while fetching from jira API',

    // Input Fields errors:
    required: '{entity} is required',
    emailFormat: 'Invalid e-mail format',
    maxCharacter: '{entity} must be less than {length} characters',
    minCharacter: '{entity} must be greater than {length} characters',
    lessThan: '{entity} must be less than {number}',
    lessThanOrEqual: '{entity} must be less or equal than {number}',
    greaterThan: '{entity} must be greater than {number}',
    greaterThanOrEqual: '{entity} must be greater than or equal {number}',
    fileSize: '{entity} size should be less than {size} MB!',
    unmatchPassword: 'Passwords you entered don\'t match',
    invalidPhoneNumber: 'Invalid Phone Number'
  },
  confirmation: {
    delete: 'Are you sure you want to delete {entity} ?',
    changeWorkingHours: 'Changing an employee\'s working hours will affect their leaves and leave requests.',
    duplicateJiraTasks: 'Jira task is already in use. Are you sure you want to save?'
  },
  emptyState: 'Currently you don\'t have any {entity}',
  home: {
    noLeaves: 'No one is off today.',
    goalDeadline: 'Less than 10 days left for this goal!'
  },
  hints: {
    jiraConventions: `You can specify a time unit after a time value "X", such as Xd, Xh or Xm,
to represent days(d), hours(h) and minutes(m), respectively (eg.4d 4h 30m).`
  }
};
