/**
 * A class that can return the help desk
 */
export class HelpDesk {
  /** @param {Object} helpDesk help desk object. */
  constructor(helpDesk = {}) {
    this.id = helpDesk.id || null;
    if (helpDesk.attributes) {
      this.name = helpDesk.attributes.name || null;
      this.link = helpDesk.attributes.link || null;
    }
  }
}
