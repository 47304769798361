import moment from 'moment';
import {LeaveDetails} from './leaveDetails';
/**
 * A class that can return the User Leave model
 */
export class UserLeave {
  /** @param {Object} userLeave FastJson object. */
  constructor(userLeave = {}) {
    this.id = userLeave.id || null;
    if (userLeave.attributes) {
      this.leaveId = userLeave.attributes.leave_id || null;
      this.name = userLeave.attributes.name || null;
      this.jiraTask = userLeave.attributes.jira_task || null;
      this.unit = userLeave.attributes.unit || [];
      this.recurrence = userLeave.attributes.recurrence || null;
      this.startDate = moment(userLeave.attributes.start_date).format('YYYY-MM-DD') || null;
      this.endDate = moment(userLeave.attributes.end_date ).format('YYYY-MM-DD') || null;
      this.totalBalance = new LeaveDetails(userLeave.attributes.total_balance);
      this.remainingBalance = new LeaveDetails(userLeave.attributes.remaining_balance);
      this.approved = new LeaveDetails(userLeave.attributes.approved);
      this.pendingApproval = new LeaveDetails(userLeave.attributes.pending_approval);
      this.rejected = new LeaveDetails(userLeave.attributes.rejected);
    }
  }
}
