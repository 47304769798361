export default [
  {
    path: '/network-error',
    name: 'NetworkError',
    component: () => import('views/errors/networkError')
  },
  {
    path: '/forbidden',
    name: 'Forbidden',
    component: () => import('views/errors/forbidden')
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import('views/errors/notFound')
  }
];
