import moment from 'moment';
/**
 * A class that can return the Office model
 */
export class Office {
  /** @param {Object} office FastJson object. */
  constructor(office = {}) {
    this.id = office.id || null;
    if (office.attributes) {
      this.name = office.attributes.name || null;
      this.timezone = office.attributes.timezone || null;
      this.weekends = office.attributes.weekends || [];
      this.fullTimeWorkingDay = office.attributes.full_time_working_day || null;
      this.businessHoursStart = office.attributes.business_hours_start ? moment(office.attributes.business_hours_start).format('HH:mm') : null;
      this.businessHoursEnd = this.businessHoursStart ? moment(this.businessHoursStart, 'HH:mm').add(this.fullTimeWorkingDay, 'hours').format('HH:mm') : null;
      this.googleCalendarId = office.attributes.google_calendar_id || null;
    } else {
      this.name = office.name || null;
      this.timezone = office.timezone || null;
      this.weekends = office.weekends || [];
      this.fullTimeWorkingDay = office.full_time_working_day || null;
      this.businessHoursStart = office.business_hours_start ? moment(office.business_hours_start).format('HH:mm') : null;
      this.businessHoursEnd = this.businessHoursStart ? moment(this.businessHoursStart, 'HH:mm').add(this.fullTimeWorkingDay, 'hours').format('HH:mm') : null;
      this.googleCalendarId = office.google_calendar_id || null;
    }
  }
}
