import {List, ListParams} from 'models/list';
import {Worklog} from 'models/worklog';
import {WorklogStatistics} from 'models/worklogStatistics';

const state = {
  worklogs: new List(),
  ongoingTask: {},
  statistics: {}
};

const getters = {
  worklogs: (state) => state.worklogs,
  ongoingTask: (state) => state.ongoingTask,
  statistics: (state) => state.statistics
};

const actions = {
  async getWorklogs({commit}, options) {
    const response = await this.$app.$http.get('/worklogs', {params: new ListParams(options)});
    if (response.status === 200) {
      commit('setWorklogs', response.data);
    } else {
      this.$app.$snotify.error(this.$app.$t('messages.error.internalServerError'));
    }
  },
  async getOngoingTask({commit}) {
    const response = await this.$app.$http.get('/worklogs/ongoing_task');
    if (response.status === 200) {
      commit('setOngoingTask', response.data);
    } else if (response.status === 404) {
      commit('setOngoingTask', {});
    } else {
      this.$app.$snotify.error(this.$app.$t('messages.error.internalServerError'));
    }
    return response.status;
  },
  async createWorklog({commit}, data) {
    const response = await this.$app.$http.post('/worklogs', data);
    if (response.status === 200) {
      commit('setOngoingTask', response.data);
      this.$app.$snotify.success(this.$app.$t('messages.success.created', {
        entity: this.$app.$tc('models.worklog.entity', 1)
      }));
    } else {
      this.$app.displayServerErrorsInToaster(response.errors);
    }
    return response.status;
  },
  // eslint-disable-next-line no-empty-pattern
  async updateWorklog({}, data) {
    const response = await this.$app.$http.put(`/worklogs/${data.id}`, data.form);
    if (response.status === 200) {
      this.$app.$snotify.success(this.$app.$t('messages.success.updated', {
        entity: this.$app.$tc('models.worklog.entity', 1)
      }));
    } else {
      this.$app.displayServerErrorsInToaster(response.errors);
    }
    return response.status;
  },
  async updateOngoingTask({commit}, data) {
    const response = await this.$app.$http.put(`/worklogs/${data.id}`, data.form);
    if (response.status === 200) {
      commit('setOngoingTask', response.data);
    } else {
      this.$app.displayServerErrorsInToaster(response.errors);
    }
    return response.status;
  },
  async destroyWorklog({commit}, {id, isOngoingTask}) {
    const response = await this.$app.$http.delete(`/worklogs/${id}`);
    if (response.status === 200) {
      if (isOngoingTask) {
        commit('setOngoingTask', {});
      }
      this.$app.$snotify.success(this.$app.$t('messages.success.deleted',
        {entity: this.$app.$tc('models.worklog.entity', 1)}));
    } else {
      this.$app.$snotify.error(this.$app.$t('messages.error.deleted',
        {entity: this.$app.$tc('models.worklog.entity', 1)}));
    }
    return response.status;
  },
  // eslint-disable-next-line no-empty-pattern
  async syncWorklogs({}, ids) {
    const response = await this.$app.$http.put('/worklogs/jira_sync', {ids: ids});
    if (response.status === 200) {
      this.$app.$snotify.success(this.$app.$t('messages.success.jiraSync',
        {entity: this.$app.$tc('models.worklog.entity', 1)}));
    } else {
      this.$app.$snotify.error(this.$app.$t('messages.error.jiraSync',
        {entity: this.$app.$tc('models.worklog.entity', 1)}));
    }
    return response.status;
  },
  async getStatistics({commit}) {
    const response = await this.$app.$http.get('/worklogs/statistics');
    if (response.status === 200) {
      commit('setWorklogStatistics', response.data);
    } else {
      this.$app.$snotify.error(this.$app.$t('messages.error.internalServerError'));
    }
    return response.status;
  }
};

const mutations = {
  setWorklogs: (state, data) => {
    state.worklogs = new List({items: data.items.map((worklog) => new Worklog(worklog)), count: data.count});
  },
  setOngoingTask: (state, data) => {
    const onGoingTask = new Worklog(data);
    state.ongoingTask = onGoingTask.endTime ? {} : onGoingTask;
  },
  setWorklogStatistics: (state, data) => {
    state.statistics = new WorklogStatistics(data);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
