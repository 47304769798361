export default [{
  path: '/users/:uuid',
  name: 'UserProfile',
  redirect: '/users/:uuid/edit/profile',
  component: () => import('views/main/users/profile/index'),
  children: [{
    path: '/users/:uuid/edit/profile',
    name: 'EditProfile',
    component: () => import('views/main/users/profile/editProfile'),
    meta: {
      requireCurrentUserOrAdmin: true
    }
  },
  {
    path: '/users/:uuid/edit/work',
    name: 'EditWorkInfo',
    component: () => import('views/main/users/profile/editWorkInfo'),
    meta: {
      requireCurrentUserOrAdmin: true
    }
  },
  {
    path: '/users/:uuid/edit/password',
    name: 'EditPassword',
    component: () => import('views/main/users/profile/editPassword'),
    meta: {
      requireCurrentUserOrAdmin: true
    }
  },
  {
    path: '/users/:uuid/permissions',
    name: 'Permissions',
    component: () => import('views/main/users/profile/permissions'),
    meta: {
      requireCurrentUserOrAdmin: true
    }
  },
  {
    path: '/users/:uuid/supervisors',
    name: 'Supervisors',
    component: () => import('views/main/users/profile/supervisors'),
    meta: {
      requireCurrentUserOrAdmin: true
    }
  },
  {
    path: '/users/:uuid/leaves',
    name: 'UserLeaves',
    component: () => import('views/main/users/profile/leaves'),
    meta: {
      requireCurrentUserOrAdmin: true
    }
  },
  {
    path: '/users/:uuid/goals',
    name: 'UserGoals',
    component: () => import('views/main/users/profile/goals'),
    meta: {
      requireCurrentUserOrAdmin: true
    }
  },
  {
    path: '/users/:uuid/assets',
    name: 'UserAssets',
    component: () => import('views/main/users/profile/userAssets'),
    meta: {
      requireCurrentUserOrAdmin: true
    }
  },
  {
    path: '/users/:uuid/documents',
    name: 'UserDocuments',
    component: () => import('views/main/users/profile/documents'),
    meta: {
      requireCurrentUserOrAdmin: true
    }
  },
  {
    path: '/users/:uuid/kudos',
    name: 'UserKudos',
    component: () => import('views/main/users/profile/kudos'),
    meta: {
      requireCurrentUserOrAdmin: true
    }
  }]
}];
