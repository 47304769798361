/**
 * A class that can return the Document model
 */
export class Document {
  /** @param {Object} document FastJson object. */
  constructor(document = {}) {
    this.id = document.id || null;
    if (document.attributes) {
      this.title = document.attributes.title || null;
      this.attachment = document.attributes.attachment || null;
    }
  }
}
