<template>
  <div class="row">
    <div class="col-md-5 col-sm-12 offset-md-7 offset-sm-0 pb-0">
      <v-select
        v-model="selection"
        @change="filterTable"
        :items="items"
        :label="label"
        outlined
        dense>
      </v-select>
    </div>
  </div>
</template>

<script>

export default {
  name: 'tableFilter',
  props: {
    label: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selection: this.items[0]
    };
  },
  methods: {
    filterTable() {
      this.$emit('filterTable', this.selection);
    }
  }
};
</script>
