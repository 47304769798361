import Vue from 'vue';
import VueRouter from 'vue-router';
import _ from 'lodash';

import store from '../store';
import errorsRoutes from './errors';
import mainRoutes from './main';
import userRoutes from './user';
import sessionRoutes from './session';

Vue.use(VueRouter);

const $store = store;
const router = new VueRouter({
  mode: 'history',
  routes: [
    ...sessionRoutes,
    ...errorsRoutes,
    ...mainRoutes,
    ...userRoutes
  ]
});

router.beforeResolve((_to, _from, next) => {
  next();
});

router.beforeEach((to, _from, next) => {
  if (to.name === 'NetworkError' || to.name === 'Forbidden' || to.name === 'NotFound') {
    next();
  } else {
    if (_.isNil($store.getters.company.id)) {
      if (to.name === 'Register' || to.name === 'RegisterSuccess') {
        next();
      } else {
        next('/register');
      }
    } else {
      if (_.isEmpty($store.getters.accessToken) && _.isNil($store.getters.currentUser.id)) {
        if (to.name === 'Login' || to.name === 'ResetPassword') {
          next();
        } else {
          next({
            path: '/login',
            query: {to: to.path}
          });
        }
      } else if (_.isEmpty($store.getters.accessToken) && !_.isNil($store.getters.currentUser.id)) {
        if (to.name === 'SetPassword') {
          next();
        } else {
          next('/set-password');
        }
      } else {
        if (to.name === 'Register' || to.name === 'RegisterSuccess' || to.name === 'Login' || to.name === 'SetPassword') {
          next('/');
        } else if (to.meta.currentUserOnly) {
          if (to.params.uuid === $store.getters.currentUser.id || to.params.uuid === 'me') {
            next();
          } else {
            next('/');
          }
        } else if (to.meta.requireAdminAuth) {
          if ($store.getters.isAdmin) {
            next();
          } else {
            next('/');
          }
        } else if (to.meta.requireCurrentUserOrAdmin) {
          if ($store.getters.isAdmin || to.params.uuid === $store.getters.currentUser.id || to.params.uuid === 'me') {
            next();
          } else {
            next('/');
          }
        } else if (to.meta.requireManagerPermission) {
          if ($store.getters.isAdmin ||
            ($store.getters.currentUser.managerPermissions &&
              $store.getters.currentUser.managerPermissions.includes(to.meta.permission))) {
            next();
          } else {
            next('/');
          }
        } else {
          next();
        }
      }
    }
  }
});

router.afterEach(() => {
  setTimeout(() => {
    const contentWrapper = document.querySelector('html');
    if (contentWrapper !== null) {
      contentWrapper.scrollTop = 0;
    }
  }, 200);
});

export default router;
