/**
 * A class that can return the Calendar configurations model
 */
export class Calendar {
  /** @param {Object} calendar FastJson Object*/
  constructor(calendar = {}) {
    this.id = calendar.id || null;
    if (calendar.attributes) {
      this.key = calendar.attributes.key || null;
      this.name = calendar.attributes.name || null;
      this.color = calendar.attributes.color || null;
      this.isPrivate = calendar.attributes.is_private || false;
      this.show = true;
    }
  }
}
