
/**
 * A class that can return the Google Event model
 */
export class GoogleEvent {
  /** @param {Object} event FastJson Object*/
  constructor(event = {}) {
    this.id = event.id || null;
    if (event.attributes) {
      this.organizer = event.attributes.organizer ? {
        fullName: event.attributes.organizer.full_name,
        email: event.attributes.organizer.email
      } : {};
      this.location = event.attributes.location;
      this.link = event.attributes.link;
    }
  }
}
