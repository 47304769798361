import {userRoles, languages} from 'constants';
import {Company} from 'models/company';
import {Theme} from 'models/theme';
import {User} from 'models/user';

const state = {
  company: localStorage && localStorage.company ? JSON.parse(localStorage.company) : new Company(),
  theme: localStorage && localStorage.theme ? JSON.parse(localStorage.theme) : new Theme(),
  enableDarkMode: localStorage && localStorage.enableDarkMode ? localStorage.enableDarkMode : false,
  currentUser: localStorage && localStorage.currentUserData ? JSON.parse(localStorage.currentUserData) : new User(),
  isAdmin: localStorage && localStorage.currentRole === userRoles.ADMIN,
  accessToken: localStorage && localStorage.accessToken ? localStorage.accessToken : '',
  currentLanguage: localStorage && localStorage.locale === 'ar' ? languages.ar : languages.en,
  rtlLayout: localStorage && localStorage.locale === 'ar',
  currentHelpDesks: localStorage && localStorage.currentHelpDesks ? JSON.parse(localStorage.currentHelpDesks) : []
};

const getters = {
  company: (state) => state.company,
  theme: (state) => state.theme,
  enableDarkMode: (state) => state.enableDarkMode,
  languages: () => languages,
  accessToken: (state) => state.accessToken,
  isAdmin: (state) => state.isAdmin,
  currentUser: (state) => state.currentUser,
  currentLanguage: (state) => state.currentLanguage,
  rtlLayout: (state) => state.rtlLayout,
  currentHelpDesks: (state) => state.currentHelpDesks
};

const actions = {
  async getCompany({commit}) {
    commit('setLoading', true);
    const hostname = window.location.hostname.split('.')[0];
    const response = await this.$app.$http.get(`${process.env.VUE_APP_PUBLIC_API_URL}/companies/host`, {
      params: {
        hostname: hostname
      }
    });
    if (response.status === 200) {
      commit('setCompany', response.data);
      commit('setTheme', response.data);
      commit('setLoading', false);
    } else {
      localStorage.removeItem('company');
      localStorage.removeItem('theme');
      commit('setLoading', false);
    }
  },
  async createNewCompany({commit}, data) {
    commit('setLoading', true);
    const response = await this.$app.$http.post(`${process.env.VUE_APP_PUBLIC_API_URL}/companies`, data);
    if (response.status === 200) {
      commit('setLoading', false);
      this.$app.$router.push({name: 'RegisterSuccess'});
    } else if (response.status === 208) {
      commit('setLoading', false);
      this.$app.$snotify.error(this.$app.$t('messages.error.domainTaken'));
    } else {
      commit('setLoading', false);
      this.$app.displayServerErrorsInToaster(response.errors);
    }
  },
  async login({commit}, {data, targetPath}) {
    commit('setLoading', true);
    const response = await this.$app.$http.post('/auth/login', data);
    if (response.status === 200) {
      commit('setCurrentUserData', new User(response.data.current_user));
      const currentLanguage = response.data.current_user.locale === 'ar' ? languages.ar : languages.en;
      const helpDeskLinks = response.data.current_user.attributes.help_desks;
      commit('setCurrentLanguage', currentLanguage);
      commit('setCurrentHelpDesks', helpDeskLinks);
      this.$app.$i18n.locale = currentLanguage.i18nLocale;
      this.$app.$vuetify.rtl = currentLanguage.locale === 'ar';
      if (response.data.access_token) {
        commit('setAccessToken', response.data.access_token);
        this.$app.$router.push(targetPath || '/');
      } else {
        this.$app.$router.push('/set-password');
      }
      commit('setLoading', false);
    } else {
      localStorage.clear();
      commit('setLoading', false);
    }
    return response;
  },
  async logout({commit}) {
    commit('clearUserSession');
    this.$app.$router.push('/login');
  },
  async resetPassword({commit}, email) {
    commit('setLoading', true);
    const response = await this.$app.$http.put('/auth/reset_password', email);
    if (response.status === 200) {
      commit('setLoading', false);
      this.$app.$snotify.success(this.$app.$t('messages.success.resetPassword'));
      this.$app.$router.push('/login');
    } else {
      commit('setLoading', false);
    }
    return response;
  },
  async switchLanguage({commit}, language) {
    commit('setCurrentLanguage', language);
    this.$app.$i18n.locale = language.i18nLocale;
    this.$app.$vuetify.rtl = language.locale === 'ar';
  },
  async updateCurrentUser({commit, state}, data) {
    commit('setLoading', true);
    const response = await this.$app.$http.put(`/users/${ state.currentUser.id }`, data);
    if (response.status === 200) {
      commit('setCurrentUserData', new User(response.data));
      commit('setLoading', false);
      this.$app.$snotify.success(this.$app.$t('messages.success.updated', {
        entity: this.$app.$tc('models.user.entity', 1)
      }));
    } else {
      commit('setLoading', false);
      this.$app.$snotify.error(this.$app.$t('messages.error.internalServerError'));
    }
  },
  async switchDarkMode({commit}, isDarkMode) {
    commit('setLoading', true);
    const response = await this.$app.$http.put(`/users/${ state.currentUser.id }/enable_dark_mode`, {
      'enable_dark_mode': `${isDarkMode}`
    });
    if (response.status === 200) {
      localStorage.setItem('enableDarkMode', isDarkMode);
      commit('setLoading', false);
    } else {
      commit('setLoading', false);
      this.$app.$snotify.error(this.$app.$t('messages.error.internalServerError'));
    }
  },
  async updateUserPassword({commit}, data) {
    commit('setLoading', true);
    const response = await this.$app.$http.put('/auth/password', data);
    if (response.status === 200) {
      commit('setLoading', false);
      if (response.data.access_token) {
        commit('setAccessToken', response.data.access_token);
        this.$app.$router.push('/');
      } else {
        this.$app.$snotify.success(this.$app.$t('messages.success.updated', {
          entity: this.$app.$tc('models.user.entity', 1)
        }));
      }
    } else {
      commit('setLoading', false);
    }
    return response;
  },
  resetSession({commit}) {
    commit('clearUserSession');
  }
};

const mutations = {
  setCompany: (state, data) => {
    const company = new Company(data);
    localStorage.setItem('company', JSON.stringify(company));
    state.company = company;
  },
  setTheme: (state, data) => {
    const theme = new Theme(data);
    localStorage.setItem('theme', JSON.stringify(theme));
    state.theme = theme;
  },
  setCurrentHelpDesks: (state, data) => {
    localStorage.setItem('currentHelpDesks', JSON.stringify(data));
    state.currentHelpDesks = data;
  },
  setCurrentUserData: (state, userData) => {
    localStorage.setItem('uuid', userData.id);
    localStorage.setItem('currentUserData', JSON.stringify(userData));
    localStorage.setItem('currentRole', userData.role);
    localStorage.setItem('enableDarkMode', userData.enableDarkMode);

    state.currentUser = userData;
    state.isAdmin = userData.role === userRoles.ADMIN;
    state.enableDarkMode = userData.enableDarkMode;
  },
  setCurrentLanguage: (state, language) => {
    localStorage.setItem('locale', language.locale);
    state.currentLanguage = language;
    state.rtlLayout = language === languages.ar;
  },
  setAccessToken: (state, accessToken) => {
    localStorage.setItem('accessToken', accessToken);
    state.accessToken = accessToken;
  },
  clearUserSession: (state) => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('uuid');
    localStorage.removeItem('currentUserData');
    localStorage.removeItem('currentRole');
    localStorage.removeItem('locale');
    localStorage.removeItem('enableDarkMode');
    localStorage.removeItem('currentHelpDesks');

    state.enableDarkMode = false;
    state.currentUser = new User();
    state.isAdmin = false;
    state.accessToken = '';
    state.currentLanguage = languages.en;
    state.rtlLayout = false;
    state.currentHelpDesks = [];
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
