import {List} from 'models/list';
import {JiraTicket} from 'models/jiraTicket';

const state = {
  organizationChart: [],
  jiraTasks: new List()
};

const getters = {
  organizationChart: (state) => state.organizationChart,
  jiraTasks: (state) => state.jiraTasks
};

const actions = {
  async getOrganizationChart({commit}) {
    commit('setLoading', true);
    const response = await this.$app.$http.get('/users/hierarchy');
    if (response.status === 200) {
      commit('setOrganizationChart', response.data);
      commit('setLoading', false);
    } else {
      commit('setLoading', false);
      this.$app.$snotify.error(this.$app.$t('messages.error.internalServerError'));
    }
  },
  async updateCompany({commit}, data) {
    commit('setLoading', true);
    const response = await this.$app.$http.put( `/companies/${data.companyId}`, data.form);
    if (response.status === 200) {
      commit('setCompany', response.data);
      commit('setLoading', false);
      this.$app.$snotify.success(this.$app.$t('messages.success.updated', {
        entity: this.$app.$tc('models.company.entity', 1)
      }));
    } else {
      commit('setLoading', false);
    }
    return response;
  },
  async updateTheme({commit}, data) {
    commit('setLoading', true);
    const response = await this.$app.$http.put(`/companies/${data.companyId}/theme`, data.form);
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setTheme', response.data);
      this.$app.$snotify.success(this.$app.$t('messages.success.updated', {
        entity: this.$app.$tc('models.theme.entity', 1)
      }));
    } else {
      commit('setLoading', false);
      this.$app.$snotify.error(this.$app.$t('messages.error.deleted',
        {entity: this.$app.$tc('models.office.entity', 1)}));
    }
  },
  async integrateJira({commit}, data) {
    commit('setLoading', true);
    const response = await this.$app.$http.put( `/companies/${data.companyId}/jira`, data.form);
    if (response.status === 200) {
      commit('setCompany', response.data);
      commit('setLoading', false);
      this.$app.$snotify.success(this.$app.$t('messages.success.updated', {
        entity: this.$app.$tc('models.company.entity', 1)
      }));
    } else {
      commit('setLoading', false);
    }
    return response;
  },
  async searchJiraTasks({commit}, options) {
    const response = await this.$app.$http.get(`/companies/jira_tasks?search_key=${options.searchKey}`);
    if (response.status === 200) {
      commit('setJiraTasks', response.data);
    } else {
      this.$app.$snotify.error(this.$app.$t('messages.error.jiraFetch'));
    }
  }
};

const mutations = {
  setOrganizationChart: (state, data) => (state.organizationChart = data),
  setJiraTasks: (state, data) => {
    state.jiraTasks = new List({
      items: data.items.map((jiraTicket) => new JiraTicket(jiraTicket)),
      count: data.count
    });
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
