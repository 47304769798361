import {Department} from './department';
import {Permission} from './permission';
import moment from 'moment';
import _ from 'lodash';
import {Team} from './team';
import {Office} from './office';
/**
 * A class that can return the User model
 */
export class User {
  /** @param {Object} user FastJson object. */
  constructor(user = {}) {
    this.id = user ? user.id : null;
    if (user && user.attributes) {
      // PROFILE DATA
      this.firstName = user.attributes.first_name || null;
      this.lastName = user.attributes.last_name || null;
      this.name = `${this.firstName} ${this.lastName}`;
      this.initials = `${this.firstName ? this.firstName[0] : ''}${this.lastName ? this.lastName[0]: ''}`.toUpperCase();
      this.birthdate = user.attributes.birthdate ? moment(user.attributes.birthdate).format('YYYY-MM-DD') : null;
      this.avatar = _.isEmpty(user.attributes.avatar) ? null : user.attributes.avatar.thumbnail_url;
      this.avatarFilename = _.isEmpty(user.attributes.avatar) ? null : user.attributes.avatar.filename;
      this.bio = user.attributes.bio || null;
      this.links = user.attributes.social_media_links || [];
      this.companyId = user.attributes.company_id || '';
      this.timezone = user.attributes.timezone || '';

      // CONTACT DATA:
      this.address = user.attributes.address || null;
      this.email = user.attributes.email || null;
      this.phoneNumber = user.attributes.phone_number || '';
      this.emergencyNumber = user.attributes.emergency_number || '';

      // JOB DATA:
      this.employeeId = user.attributes.employee_id ? user.attributes.employee_id : null;
      this.department = new Department(user.attributes.department || {});
      this.teams = user.attributes.teams ? _.map(user.attributes.teams, (team) => new Team(team)) : [];
      this.followedUsers = [];
      if (user.attributes.followed_users) {
        this.followedUsers = _.map(user.attributes.followed_users, (followedUser) => new User(followedUser));
      }
      this.followers = [];
      if (user.attributes.followers) {
        this.followers = _.map(user.attributes.followers, (follower) => new User(follower));
      }
      this.isFollowing = user.attributes.is_following ? user.attributes.is_following : false;
      this.isManager = user.attributes.is_manager ? user.attributes.is_manager : false;
      this.office = user.attributes.office ? new Office(user.attributes.office) : {};
      this.jobTitle = user.attributes.job_title || null;
      this.jobType = user.attributes.job_type || null;
      this.workingHoursPerDay = user.attributes.working_hours_per_day || '';
      this.role = user.attributes.role || null;
      this.theOnlyAdmin = user.attributes.the_only_admin || null;
      this.level = user.attributes.level || null;
      this.reporterId = user.attributes.reporter_id || null;
      this.startDate = user.attributes.start_date ? moment(user.attributes.start_date).format('YYYY-MM-DD') : null;

      this.enableDarkMode = user.attributes.enable_dark_mode || false;
      this.googleSynced = user.attributes.google_synced || false;
      // PERMISSIONS:
      this.supervisingPermissions = user.attributes.supervising_permissions ?
        _.map(user.attributes.supervising_permissions, (permission) => new Permission(permission)) :
        [];
      this.managerPermissions = user.attributes.manager_permissions || [];
      this.jiraUsername = user.attributes.jira_username || null;
    }
  }
}
