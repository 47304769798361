<template>
  <v-row justify="center">
    <v-dialog v-model="modal.show" max-width="500">
      <v-card>
        <v-card-title class="headline background">{{ modal.title }}</v-card-title>
        <v-card-text>
          <app-form
            ref="form"
            :fields="modal.fields"
            :submitButton="modal.submitButton"
            @submitted="handleSubmit"
            @selection-changed="selectionChanged"
            @date-changed="dateChanged"
            @time-changed="timeChanged"
            @file-attached="handleAttach"
            @text-changed="textChanged"
            :resetForm="modal.resetForm"
            disableCancelButton
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    modal: {
      type: Object,
      required: true
    }
  },
  methods: {
    handleSubmit(data) {
      this.$emit('submit-modal', data);
    },
    selectionChanged(data) {
      this.$emit('selection-changed', data);
    },
    dateChanged(data) {
      this.$emit('date-changed', data);
    },
    timeChanged(data) {
      this.$emit('time-changed', data);
    },
    handleAttach(data) {
      this.$emit('file-attached', data);
    },
    textChanged(data) {
      this.$emit('text-changed', data);
    }
  }
};
</script>
