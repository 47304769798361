<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-on="on" v-bind="attrs" @click="clicked"
        :class="classes" :disabled="disabled" :color="btnColor"
        :small="btnSize === 'small'" :x-small="btnSize === 'x-small'"
        :large="btnSize === 'large'" :x-large="btnSize === 'x-large'">
        <v-icon :small="btnSize === 'small'" :x-small="btnSize === 'x-small'"
          :large="btnSize === 'large'" :x-large="btnSize === 'x-large'">
          {{ icon }}
        </v-icon>
      </v-btn>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true
    },
    tooltip: {
      type: String,
      required: true
    },
    classes: {
      type: String,
      required: false
    },
    iconSize: {
      type: String,
      required: false,
      default: 'medium'
    },
    btnSize: {
      type: String,
      required: false,
      default: 'small'
    },
    btnColor: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    clicked() {
      this.$emit('click');
    }
  }
};
</script>
