import moment from 'moment';
/**
 * A class that can return the Holiday model
 */
export class Holiday {
  /** @param {Object} holiday FastJson object. */
  constructor(holiday = {}) {
    this.id = holiday.id || null;
    if (holiday.attributes) {
      this.name = holiday.attributes.name || null;
      this.officeId = holiday.attributes.office_id || null;
      this.officeName = holiday.attributes.office_name || null;
      this.startDate = holiday.attributes.start_date ? moment(holiday.attributes.start_date).format('DD MMM YYYY') : null;
      this.endDate = holiday.attributes.end_date ? moment(holiday.attributes.end_date).format('DD MMM YYYY') : null;
    }
  }
}
