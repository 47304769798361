import {List, ListParams} from 'models/list';
import {HelpDesk} from 'models/helpDesk';

const state = {
  helpDesks: new List()
};

const getters = {
  helpDesks: (state) => state.helpDesks
};

const actions = {
  async getHelpDesks({state, commit}, options) {
    commit('setLoading', true);
    const response = await this.$app.$http.get('/help_desks', {params: {...new ListParams(options)}});
    commit('setLoading', false);
    if (response.status === 200) {
      commit('setHelpDesks', response.data);
      commit('setCurrentHelpDesks', state.helpDesks.items);
    }
    return response.status;
  },
  async createHelpDesk({commit}, data) {
    commit('setLoading', true);
    const response = await this.$app.$http.post('/help_desks', data);
    commit('setLoading', false);
    if (response.status === 200) {
      this.$app.$snotify.success(this.$app.$t('messages.success.updated', {
        entity: this.$app.$tc('models.helpDesk.entity', 1)
      }));
    }
    return response;
  },
  async updateHelpDesk({commit}, data) {
    commit('setLoading', true);
    const response = await this.$app.$http.put(`/help_desks/${data.helpDeskId}`, data.form);
    commit('setLoading', false);
    if (response.status === 200) {
      this.$app.$snotify.success(this.$app.$t('messages.success.updated', {
        entity: this.$app.$tc('models.helpDesk.entity', 1)
      }));
    }
    return response;
  },
  async destroyHelpDesk({commit}, id) {
    commit('setLoading', true);
    const response = await this.$app.$http.delete(`/help_desks/${id}`);
    commit('setLoading', false);
    if (response.status === 200) {
      this.$app.$snotify.success(this.$app.$t('messages.success.deleted',
        {entity: this.$app.$tc('models.helpDesk.entity', 1)}));
    } else {
      this.$app.$snotify.error(this.$app.$t('messages.error.deleted',
        {entity: this.$app.$tc('models.helpDesk.entity', 1)}));
    }
    return response.status;
  }
};

const mutations = {
  setHelpDesks: (state, data) => {
    state.helpDesks = new List({items: data.items.map((helpDesk) => new HelpDesk(helpDesk)), count: data.count});
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
