import _ from 'lodash';
/**
 * A class that can return the Company model
 */
export class Company {
  /** @param {Object} company FastJson Object*/
  constructor(company = {}) {
    this.id = company.id || null;
    if (company.attributes) {
      this.industry = company.attributes.industry || null;
      this.name = company.attributes.name || null;
      this.jiraURL = company.attributes.jira_url || null;
      this.employeeCountRange = company.attributes.employee_count_range || null;
      this.logo = _.isEmpty(company.attributes.logo) ? null : company.attributes.logo.url;
      this.logoName = _.isEmpty(company.attributes.logo) ? null : company.attributes.logo.filename;
    }
  }
}
