import {List, ListParams} from 'models/list';
import {Kudo} from 'models/kudo';

const state = {
  kudos: new List(),
  personalKudos: new List(),
  weeklyKudos: new List(),
  kudoEditted: new List()
};

const getters = {
  kudos: (state) => state.kudos,
  personalKudos: (state) => state.personalKudos,
  weeklyKudos: (state) => state.weeklyKudos,
  kudoEditted: (state) => state.kudoEditted
};

const actions = {
  async getKudos({commit}, options) {
    commit('setLoading', true);
    const response = await this.$app.$http.get('/kudos', {params: new ListParams(options)});
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setKudos', response.data);
    } else {
      commit('setLoading', false);
    }
    return response.status;
  },
  async createKudo({commit}, data) {
    commit('setLoading', true);
    const response = await this.$app.$http.post('/kudos', data);
    if (response.status === 200) {
      commit('setLoading', false);
      this.$app.$snotify.success(this.$app.$t('messages.success.created',
        {entity: this.$app.$tc('models.kudo.entity', 1)}));
    } else {
      commit('setLoading', false);
      this.$app.$snotify.error(this.$app.$t('messages.error.created',
        {entity: this.$app.$tc('models.kudo.entity', 1)}));
    }
    return response;
  },
  async destroyKudo({commit}, id) {
    commit('setLoading', true);
    const response = await this.$app.$http.delete(`/kudos/${id}`);
    if (response.status === 200) {
      commit('setLoading', true);
      this.$app.$snotify.success(this.$app.$t('messages.success.deleted',
        {entity: this.$app.$tc('models.kudo.entity', 1)}));
    } else {
      commit('setLoading', false);
      this.$app.$snotify.error(this.$app.$t('messages.error.deleted',
        {entity: this.$app.$tc('models.office.entity', 1)}));
    }
    return response.status;
  },
  async getPersonalKudos({commit, rootState}, data) {
    commit('setLoading', true);
    const uuid = data.uuid === 'me' ? rootState.session.currentUser.id : data.uuid;
    const response = await this.$app.$http.get(`/users/${uuid}/kudos`, {params: new ListParams(data)});
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setPersonalKudos', response.data);
    } else {
      commit('setLoading', false);
    }
    return response.status;
  },
  async getWeeklyKudos({commit}) {
    commit('setLoading', true);
    const response = await this.$app.$http.get('/weekly_kudos');
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setWeeklyKudos', response.data);
    } else {
      commit('setLoading', false);
    }
    return response.status;
  },
  async getKudoEditted({commit}, id) {
    commit('setLoading', true);
    const response = await this.$app.$http.get(`/kudos/${id}`);
    if (response.status === 200 ) {
      commit('setKudoEditted', response.data);
      commit('setLoading', false);
    } else {
      this.$app.$snotify.error(this.$app.$t('messages.error.notFound',
        {entity: this.$app.$tc('models.kudo.entity', 1)}));
    }
  },
  async updateKudo({commit}, {id, data}) {
    commit('setLoading', true);
    const response = await this.$app.$http.put(`/kudos/${id}`, data);
    if (response.status === 200) {
      commit('setLoading', false);
      this.$app.$snotify.success(this.$app.$t('messages.success.updated',
        {entity: this.$app.$tc('models.kudo.entity', 1)}));
    } else {
      this.$app.$snotify.error(this.$app.$t('messages.error.updated',
        {entity: this.$app.$tc('models.kudo.entity', 1)}));
    }
  }
};

const mutations = {
  setKudos: (state, data) => {
    state.kudos = new List({items: data.items.map((kudo) => new Kudo(kudo)), count: data.count});
  },
  setPersonalKudos: (state, data) => {
    state.personalKudos = new List({items: data.items.map((kudo) => new Kudo(kudo)), count: data.count});
  },
  setWeeklyKudos: (state, data) => {
    state.weeklyKudos = new List({items: data.items.map((kudo) => new Kudo(kudo)), count: data.count});
  },
  setKudoEditted: (state, data) => {
    const itemArray = [data.items];
    state.kudoEditted = new List({items: itemArray.map((kudo) => new Kudo(kudo)), count: data.count});
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
