export default {
  mainMenu: {
    home: 'Home',
    calendar: 'Calendar',
    organizationChart: 'Organization Chart',
    offices: 'Offices',
    settings: 'Settings',
    company: 'Company',
    users: 'Users',
    profile: 'My Profile',
    departments: 'Departments',
    companyProfile: 'Company Profile',
    companyTheme: 'Company Theme',
    teams: 'Teams',
    leaves: 'Leaves',
    assets: 'Assets',
    goals: 'Goals',
    otherGoals: 'Other\'s Goals',
    officialHolidays: 'Official Holidays',
    integrations: 'Integrations',
    requests: 'Requests',
    leaveRequests: 'Manage Requests',
    goalRequests: 'Manage Requests',
    pending: 'Pending',
    history: 'History',
    myCalendars: 'My Calendars',
    helpDesks: 'Help Desks',
    kudos: 'kudos'
  },
  adminMenu: {
    invitation: 'New Invitation',
    newTeam: 'New Team'
  },
  accountNavMenu: {
    publicProfile: 'Public profile',
    editProfile: 'Update public profile',
    editWorkInfo: 'Update work info',
    editPassword: 'Update password',
    permissions: 'My Permissions',
    supervisors: 'My Supervisors',
    worklogs: 'Work Logs',
    leaves: 'My Leaves',
    assets: 'My Assets',
    goals: 'My Goals',
    kudos: 'My Kudos',
    editKudoRequest: 'Edit Kudos Request',
    documents: 'My Documents',
    requestNewLeave: 'New Leave',
    newGoal: 'New Goal',
    newDocument: 'New Document',
    editLeaveRequest: 'Edit leave request',
    logout: 'Log out',
    editHelpDeskLinks: 'Edit Links',
    requestNewKudos: 'New Kudos'
  },
  home: {
    birthdays: 'Birthday | Birthdays',
    anniversaries: 'Work Anniversary | Work Anniversaries',
    leaves: 'Who\'s Off Today?',
    googleEvents: 'Google Events',
    goals: 'My Goals',
    worklogSummary: '{month} Worklog Summary',
    totalWorkingDays: '{days} working days ({hours} hrs)',
    kudos: 'Weekly Kudos'
  },
  userMenu: {
    profile: 'Profile',
    work: 'Work Info',
    photo: 'Photo',
    password: 'Password',
    permissions: 'Permissions',
    leaves: 'Leaves',
    assets: 'Assets',
    documents: 'Documents',
    teams: 'Teams',
    goals: 'Goals',
    supervisors: 'Supervisors',
    kudos: 'Kudos'
  },
  editProfile: {
    title: 'Public profile',
    subtitle: 'Add information about yourself'
  },
  editWork: {
    title: 'Work Info',
    subtitle: 'Update work information'
  },
  editAvatar: {
    title: 'Avatar',
    subtitle: 'Add a nice photo of yourself for your profile.',
    hint: 'Add / Change Image',
    placeholder: 'No file selected'
  },
  editPassword: {
    title: 'Password',
    subtitle: 'Change your password here.'
  },
  permissions: {
    title: 'Permissions',
    subtitle: 'View all your allowed actions.'
  },
  leaves: {
    title: 'Leaves',
    subtitle: 'View all Leaves.'
  },
  kudos: {
    title: 'Kudos',
    subtitle: 'View all Kudos'
  },
  assets: {
    title: 'Assets',
    subtitle: 'View all Assets.'
  },
  documents: {
    title: 'Documents',
    subtitle: 'View all Documents.'
  },
  supervisors: {
    title: 'Supervisors',
    subtitle: 'View all your supervisors and their permissions.'
  },
  editScope: {
    title: 'Edit User',
    subtitle: 'Edit User Fields'
  },
  goals: {
    title: 'Goals',
    subtitle: 'View all Goals.'
  },
  editUserProfile: {
    title: 'Edit User Data',
    subtitle: 'Complete or Edit User Data'
  },
  editUserPermissions: {
    title: 'Edit User Permissions',
    subtitle: 'Manage User Permissions'
  },
  syncWithGoogle: 'Sync With Google',
  joinMeeting: 'Join with Google Meet'
};
