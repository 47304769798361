import _ from 'lodash';
/**
 * A class that can return the Permission model
 */
export class Permission {
  /** @param {Object} permission FastJson object. */
  constructor(permission = {}) {
    this.id = permission.id || null;
    this.sym = permission.attributes.permission_sym;
    this.enabled = permission.attributes.subject_users && permission.attributes.subject_users.length !== 0;
    this.subjectUsers = permission.attributes.subject_users ?
      _.map(permission.attributes.subject_users, ((user) => ({id: user.id, name: `${user.first_name} ${user.last_name} `}))):
      [];
  }
}
