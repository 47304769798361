import {Team} from './team';
import {User} from './user';
import _ from 'lodash';
/**
 * A class that can return the Department model
 */
export class Department {
  /** @param {Object} department FastJson object. */
  constructor(department = {}) {
    this.id = department.id || null;
    if (department.attributes) {
      this.name = department.attributes.name || null;
      this.description = department.attributes.description || null;
      this.teams = !_.isEmpty(department.attributes.teams) ?
        department.attributes.teams.map((team) => new Team(team)) : [];
      this.users = !_.isEmpty(department.attributes.users) ?
        department.attributes.users.map((user) => new User(user)) : [];
    } else {
      this.name = department.name || null;
      this.description = department.description || null;
    }
  }
}
