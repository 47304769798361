import {List, ListParams} from 'models/list';
import {Document} from 'models/document';

const state = {
  documents: new List()
};

const getters = {
  documents: (state) => state.documents
};

const actions = {
  async getDocuments({commit}, data) {
    const response = await this.$app.$http.get('/documents', {params: new ListParams(data)});
    if (response.status === 200) {
      commit('setDocuments', response.data);
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async createDocument({}, data) {
    const response = await this.$app.$http.post('/documents', data);
    if (response.status === 200) {
      this.$app.$snotify.success(this.$app.$t('messages.success.updated', {
        entity: this.$app.$tc('models.document.entity', 1)
      }));
    }
    return response;
  },
  // eslint-disable-next-line no-empty-pattern
  async destroyDocument({}, documentId) {
    const response = await this.$app.$http.delete(`/documents/${documentId}`);
    if (response.status === 200) {
      this.$app.$snotify.success(this.$app.$t('messages.success.deleted',
        {entity: this.$app.$tc('models.document.entity', 1)}));
    } else {
      this.$app.$snotify.error(this.$app.$t('messages.error.deleted',
        {entity: this.$app.$tc('models.document.entity', 1)}));
    }
    return response.status;
  },
  // eslint-disable-next-line no-empty-pattern
  async downloadDocument({}, data) {
    const response = await this.$app.$http.get(data.url, {responseType: 'arraybuffer'});
    if (response.status === 200) {
      this.$app.downloadFile(response.data, data.filename);
    }
  }
};

const mutations = {
  setDocuments: (state, data) => {
    state.documents = new List({items: data.items.map((document) => new Document(document)), count: data.count});
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
