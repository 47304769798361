import {List, ListParams} from 'models/list';
import {Office} from 'models/office';

const state = {
  offices: new List()
};

const getters = {
  offices: (state) => state.offices
};

const actions = {
  async getOffices({commit}, options) {
    commit('setLoading', true);
    const response = await this.$app.$http.get('/offices', {params: new ListParams(options)});
    if (response.status === 200) {
      commit('setOffices', response.data);
      commit('setLoading', false);
    } else {
      commit('setLoading', false);
      this.$app.$snotify.error(this.$app.$t('messages.error.internalServerError'));
    }
  },
  async createOffice({commit}, data) {
    commit('setLoading', true);
    const response = await this.$app.$http.post('/offices', data);
    if (response.status === 200) {
      commit('setLoading', false);
      this.$app.$snotify.success(this.$app.$t('messages.success.created', {
        entity: this.$app.$tc('models.office.entity', 1)
      }));
    } else {
      commit('setLoading', false);
    }
    return response;
  },
  async updateOffice({commit}, data) {
    commit('setLoading', true);
    const response = await this.$app.$http.put(`/offices/${data.officeId}`, data.form);
    if (response.status === 200) {
      commit('setLoading', false);
      this.$app.$snotify.success(this.$app.$t('messages.success.updated', {
        entity: this.$app.$tc('models.office.entity', 1)
      }));
    } else {
      commit('setLoading', false);
    }
    return response;
  },
  async destroyOffice({commit}, id) {
    commit('setLoading', true);
    const response = await this.$app.$http.delete(`/offices/${id}`);
    if (response.status === 200) {
      commit('setLoading', false);
      this.$app.$snotify.success(this.$app.$t('messages.success.deleted',
        {entity: this.$app.$tc('models.office.entity', 1)}));
    } else {
      commit('setLoading', false);
      this.$app.$snotify.error(this.$app.$t('messages.error.deleted',
        {entity: this.$app.$tc('models.office.entity', 1)}));
    }
    return response.status;
  }
};

const mutations = {
  setOffices: (state, data) => {
    state.offices = new List({items: data.items.map((office) => new Office(office)), count: data.count});
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
