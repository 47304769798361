/**
 * A class that can return the Theme model
 */
export class Theme {
  /** @param {Object} theme FastJson object */
  constructor(theme = {}) {
    if (theme.attributes) {
      this.primaryColor = theme.attributes.primary_color || '#ad3b28';
      this.secondaryColor = theme.attributes.secondary_color || '#6c757d';
    }
  }
}
