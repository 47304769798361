import {List, ListParams} from 'models/list';
import {Holiday} from 'models/holiday';

const state = {
  holidays: new Holiday()
};

const getters = {
  holidays: (state) => state.holidays
};

const actions = {
  async getHolidays({commit}, data) {
    commit('setLoading', true);
    const response = await this.$app.$http.get('/holidays', {params: new ListParams(data)});
    if (response.status === 200) {
      commit('setHolidays', response.data);
      commit('setLoading', false);
    } else {
      commit('setLoading', false);
      this.$app.$snotify.error(this.$app.$t('messages.error.internalServerError'));
    }
  },
  async createHoliday({commit}, data) {
    commit('setLoading', true);
    const response = await this.$app.$http.post('/holidays', data);
    if (response.status === 200) {
      commit('setLoading', false);
      this.$app.$snotify.success(this.$app.$t('messages.success.created', {
        entity: this.$app.$tc('models.holiday.entity', 1)
      }));
    } else {
      commit('setLoading', false);
    }
    return response;
  },
  async updateHoliday({commit}, data) {
    commit('setLoading', true);
    const response = await this.$app.$http.put(`/holidays/${data.id}`, data.form);
    if (response.status === 200) {
      commit('setLoading', false);
      this.$app.$snotify.success(this.$app.$t('messages.success.updated', {
        entity: this.$app.$tc('models.holiday.entity', 1)
      }));
    } else {
      commit('setLoading', false);
    }
    return response;
  },
  async destroyHoliday({commit}, id) {
    commit('setLoading', true);
    const response = await this.$app.$http.delete(`holidays/${id}`);
    if (response.status === 200) {
      commit('setLoading', false);
      this.$app.$snotify.success(this.$app.$t('messages.success.deleted',
        {entity: this.$app.$tc('models.holiday.entity', 1)}));
    } else {
      commit('setLoading', false);
      this.$app.$snotify.error(this.$app.$t('messages.error.deleted',
        {entity: this.$app.$tc('models.holiday.entity', 1)}));
    }
    return response.status;
  }
};

const mutations = {
  setHolidays: (state, data) => {
    state.holidays = new List({items: data.items.map((holiday) => new Holiday(holiday)), count: data.count});
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
