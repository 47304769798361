import moment from 'moment';
/**
 * A class that can return the Leave model
 */
export class Leave {
  /** @param {Object} leave FastJson object. */
  constructor(leave = {}) {
    this.id = leave.id || null;
    if (leave.attributes) {
      this.name = leave.attributes.name || null;
      this.totalBalance = leave.attributes.total_balance || null;
      this.carryOverBalance = leave.attributes.carry_over_balance || null;
      this.maxConsecutiveDays = leave.attributes.max_consecutive_days || null;
      this.recurrence = leave.attributes.recurrence || null;
      this.unit = leave.attributes.unit || [];
      this.requiresApproval = leave.attributes.requires_approval || false;
      this.startDate = leave.attributes.start_date ? moment(leave.attributes.start_date).format('YYYY-MM-DD') : null;
      this.endDate = leave.attributes.end_date ? moment(leave.attributes.end_date).format('YYYY-MM-DD') : null;
      this.canOverlap = leave.attributes.can_overlap || false;
      this.googleCalendarId = leave.attributes.google_calendar_id || null;
    }
  }
}
