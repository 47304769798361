import {List, ListParams} from 'models/list';
import {Goal} from 'models/goal';

const state = {
  goals: new List(),
  goal: new Goal()
};

const getters = {
  goals: (state) => state.goals,
  goal: (state) => state.goal
};
const actions = {
  async getGoals({commit, rootState}, options) {
    commit('setLoading', true);
    const params = new ListParams(options);
    const response = await this.$app.$http.get('/goals', {params: params});
    if (response.status === 200) {
      commit('setLoading', false);
      commit('setGoals', response.data);
    } else {
      commit('setLoading', false);
      this.$app.displayServerErrorsInToaster(response.errors);
    }
  },
  async getGoal({commit}, id) {
    commit('setLoading', true);
    const response = await this.$app.$http.get(`/goals/${id}`);
    if (response.status === 200) {
      commit('setGoal', response.data);
      commit('setLoading', false);
    } else {
      commit('setLoading', false);
      this.$app.$router.push({name: 'NewGoal'});
      this.$app.$snotify.error(this.$app.$t('messages.error.notFound',
        {entity: this.$app.$tc('models.goal.entity', 1)}));
    }
  },
  async createGoal({commit}, data) {
    commit('setLoading', true);
    const response = await this.$app.$http.post('/goals', data);
    if (response.status === 200) {
      commit('setLoading', false);
      this.$app.$snotify.success(this.$app.$t('messages.success.created', {
        entity: this.$app.$tc('models.goal.entity', 1)
      }));
    } else {
      commit('setLoading', false);
    }
    return response;
  },
  async updateGoal({commit}, data) {
    commit('setLoading', true);
    const response = await this.$app.$http.put(`/goals/${data.goalId}`, data.form);
    if (response.status === 200) {
      commit('setLoading', false);
      this.$app.$snotify.success(this.$app.$t('messages.success.updated', {
        entity: this.$app.$tc('models.goal.entity', 1)
      }));
    } else {
      commit('setLoading', false);
    }
    return response;
  },
  async destroyGoal({commit}, id) {
    commit('setLoading', true);
    const response = await this.$app.$http.delete(`/goals/${id}`);
    if (response.status === 200) {
      commit('setLoading', false);
      this.$app.$snotify.success(this.$app.$t('messages.success.deleted',
        {entity: this.$app.$tc('models.goal.entity', 1)}));
    } else {
      commit('setLoading', false);
      this.$app.$snotify.error(this.$app.$t('messages.error.deleted',
        {entity: this.$app.$tc('models.goal.entity', 1)}));
    }
  },
  async manageGoal({commit}, data) {
    commit('setLoading', true);
    const response = await this.$app.$http.put(`/goals/${data.id}/status`, {status: data.status});
    if (response.status === 200) {
      this.$app.$snotify.success(this.$app.$t('messages.success.updated', {entity: this.$app.$tc('models.goal.entity', 1)}));
      commit('removeGoalFromPending', data.index);
      commit('setLoading', false);
    } else {
      commit('setLoading', false);
      this.$app.$snotify.error(this.$app.$t('messages.error.updated',
        {entity: this.$app.$tc('models.goal.entity', 1)}));
    }
    return response.status;
  },
  async updateGoalProgress({commit}, data) {
    commit('setLoading', true);
    const response = await this.$app.$http.put(`/goals/${data.id}/progress`, data.form);
    if (response.status === 200) {
      commit('setLoading', false);
      this.$app.$snotify.success(this.$app.$t('messages.success.updated', {
        entity: this.$app.$tc('models.goal.entity', 1)
      }));
    } else {
      commit('setLoading', false);
    }
    return response;
  },
  // eslint-disable-next-line no-empty-pattern
  async downloadGoalAttachment({}, data) {
    const response = await this.$app.$http.get(data.url, {responseType: 'arraybuffer'});
    if (response.status === 200) {
      this.$app.downloadFile(response.data, data.filename);
    }
  }
};

const mutations = {
  setGoal: (state, data) => state.goal = new Goal(data),
  setGoals: (state, data) => {
    state.goals = new List({items: data.items.map((goal) => new Goal(goal)), count: data.count});
  },
  removeGoalFromPending: (state, {index}) => {
    state.goals.items.splice(index, 1);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
